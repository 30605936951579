import React from "react";
import OperationalOverview from "./screens/Stepper1/OperationalOverview";
import StarRateOne from "./screens/Stepper1/StarRateOne";
import FireArea from "./screens/Stepper1/FireArea";
import Occupancy from "./screens/Stepper1/Occupancy";
import Construction from "./screens/Stepper1/Construction";
import StarRateTwo from "./screens/Stepper2/StarRateTwo";
import EssentialsService from "./screens/Stepper2/EssentialsService";
import Chart from "./screens/Chart/Chart";
import ProtectionOne from "./screens/Stepper2/ProtectionOne";
import HydrantSystem from "./screens/Stepper3/HydrantSystem";
import WaterSupply from "./screens/Stepper3/WaterSupply";
import TownMain from "./screens/Stepper3/TownMain";
import Pump1 from "./screens/Stepper3/Pump1";
import Pump2 from "./screens/Stepper3/Pump2";
import Pump3 from "./screens/Stepper3/Pump3";
import Pump4 from "./screens/Stepper3/Pump4";
import FireDetection from './screens/Stepper4/FireDetection';
import FireResponse from './screens/Stepper4/FireResponse';
import ExternalExposures from './screens/Stepper4/ExternalExposures';
import SiteSecurity from './screens/Stepper4/SiteSecurity';
import SitePhotos from './screens/Stepper4/SitePhotos';
import Liability from './screens/Stepper5/Liability';
import MFLPage from './screens/Stepper5/MFLPage';
import NLEPage from "./screens/Stepper5/NLEPage";
import WLEPage2 from "./screens/Stepper5/WLEPage2";
import BasicDetails from "./screens/Assesment/BasicDetails";
import Data from './screens/Stepper5/Data';
import WLELiability from './screens/Stepper5/WLELiability';
import FireDetectionProtection from "./screens/Stepper5/FireDetectionProtection";
import ManagementPractice from "./screens/Stepper5/ManagementPractice"
import NaturalHazardsExposure from "./screens/Stepper5/NaturalHazardsExposure";
import NaturalHazardsRecommendation from "./screens/Stepper5/NaturalHazardsRecommendation";
import SpecialHazardRi from './screens/Stepper6/SpecialHazardRi';
import AdditionalDetails from './screens/Stepper7/AdditionalDetails';
import Priority from './screens/Stepper6/Priority';
import ClientResponse from './screens/Stepper6/ClientResponse';
import ClientComment from './screens/Stepper6/ClientComment';
import Rearrange from './screens/Stepper6/Rearrange';
import Pdf from './screens/Stepper7/Pdf';
import ExecutiveSummary from './screens/Stepper7/ExecutiveSummary';
import AditionalAssessment from './screens/Stepper1/AditionalAssessment';
import StarRateTwoRI from './screens/Stepper2/StarRateTwoRI';
import FireDetectionProtectionRI from "./screens/Stepper5/FireDetectionProtectionRI";
import ManagementPracticeRI from './screens/Stepper5/ManagementPracticeRI';
import NaturalHazardsRecommendationRI from './screens/Stepper5/NaturalHazardsRecommendationRI';
import StarRateOneRI from './screens/Stepper1/StarRateOneRI';


export default function Pages({
  page,
  getPage,
  formData,
  handlePreviousPage,
  handleWlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  handleWLENextPage,
  callingBackend,
  setCallingBackend,
  skipcallingBackend,
  setSkipCallingBackend,
  handleTwoPreviousPage,
  handleTwoNextPage,
  handleNextPumpPage,
}) {
  switch (page) {
    case 1:
      return (
        <AditionalAssessment
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          formData={formData}
        />
      );
    case 2:
      return (
        <OperationalOverview
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 3:
      return (
        <Construction
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 4:
      return (
        <StarRateOne
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 5:
      return (
        <StarRateOneRI
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 6:
      return (
        <Occupancy
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 7:
      return (
        <FireArea
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 8:
      return (
        <StarRateTwo
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 9:
      return (
        <StarRateTwoRI
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 10:
      return (
        <EssentialsService
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 11:
      return (
        <ProtectionOne
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
          handleTwoNextPage={handleTwoNextPage}
        />
      );
    case 12:
      return (
        <WaterSupply
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 13:
      return (
        <HydrantSystem
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
          handleTwoPreviousPage={handleTwoPreviousPage}
        />
      );
    //next
    case 14:
      return (
        <TownMain
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 15:
      return (
        <Pump1
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          skipcallingBackend={skipcallingBackend}
          setSkipCallingBackend={setSkipCallingBackend}
          formData={formData}
          getPage={getPage}
          handleNextPumpPage={handleNextPumpPage}
        />
      );
    case 16:
      return (
        <Pump2
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          skipcallingBackend={skipcallingBackend}
          setSkipCallingBackend={setSkipCallingBackend}
          formData={formData}
          getPage={getPage}
          handleNextPumpPage={handleNextPumpPage}
        />
      );
    case 17:
      return (
        <Pump3
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          skipcallingBackend={skipcallingBackend}
          setSkipCallingBackend={setSkipCallingBackend}
          formData={formData}
          getPage={getPage}
          handleNextPumpPage={handleNextPumpPage}
        />
      );
    case 18:
      return (
        <Pump4
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          skipcallingBackend={skipcallingBackend}
          setSkipCallingBackend={setSkipCallingBackend}
          formData={formData}
          getPage={getPage}
          handleTwoNextPage={handleTwoNextPage}
        />
      );
    case 19:
      return (
        <Chart
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          skipcallingBackend={skipcallingBackend}
          setSkipCallingBackend={setSkipCallingBackend}
          formData={formData}
          getPage={getPage}
        />
      );
    case 20:
      return (
        <FireDetection
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
          handleTwoPreviousPage={handleTwoPreviousPage}
        />
      );
    case 21:
      return (
        <FireResponse
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 22:
      return (
        <FireDetectionProtection
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 23:
      return (
        <FireDetectionProtectionRI
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 24:
      return (
        <ManagementPractice
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 25:
      return (
        <ManagementPracticeRI
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 26:
      return (
        <NaturalHazardsExposure
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 27:
      return (
        <NaturalHazardsRecommendation
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 28:
      return (
        <NaturalHazardsRecommendationRI
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 29:
      return (
        <ExternalExposures
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 30:
      return (
        <SiteSecurity
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 31:
      return (
        <SitePhotos
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 32:
      return (
        <BasicDetails
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          skipcallingBackend={skipcallingBackend}
          setSkipCallingBackend={setSkipCallingBackend}
          formData={formData}
          getPage={getPage}
        />
      );
    case 33:
      return (
        <Liability
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 34:
      return (
        <MFLPage
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 35:
      return (
        <NLEPage
          handleNextPage={handleNextPage}
          handleWLENextPage={handleWLENextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 36:
      return (
        <WLELiability
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 37:
      return (
        <WLEPage2
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 38:
      return (
        <SpecialHazardRi
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          handleWlePreviousPage={handleWlePreviousPage}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 39:
      return (
        <Rearrange
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 40:
      return (
        <ExecutiveSummary
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 41:
      return (
        <AdditionalDetails
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    case 42:
      return (
        <Pdf
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
    default:
      return (
        <Data
          handleNextPage={handleNextPage}
          callingBackend={callingBackend}
          setCallingBackend={setCallingBackend}
          handlePreviousPage={handlePreviousPage}
          previouscallingBackend={previouscallingBackend}
          setPreviousCallingBackend={setPreviousCallingBackend}
          formData={formData}
        />
      );
  }
}
