import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Grid, Typography, Box, Rating, Stack } from "@mui/material";
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  Title,
} from "chart.js";
import Cutout from "../../assets/img/cutout.png";
import Star from "../../assets/img/Star.png";
import Outline_Star from "../../assets/img/Outline_Star.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title
);

export default function RateChart({
  setOverallScore,
  overallScore,
  formData,
  setOccupancyStar,
  setFire_d_p_Star,
  setManagementStar,
  setSpecialStar,
  setNaturalStar,
}) {
  const mflvalue = (mfl) => {
    if (mfl <= 50) {
      return 16;
    } else if (mfl > 50 && mfl <= 75) {
      return 14;
    } else {
      return 12;
    }
  };
  const SelectStar = (value) => {
    if (value <= 49) {
      return 1;
    } else if (value > 49 && value <= 64) {
      return 2;
    } else if (value > 64 && value <= 79) {
      return 3;
    } else if (value > 79 && value <= 89) {
      return 4;
    } else {
      return 5;
    }
  };

  // Define data here
  const data = {
    labels: [
      "Inherent Occupancy Risk",
      "Fire Detection & Protection",
      "Management Practices",
      "Special Hazards",
      "Natural Hazards",
    ],
    datasets: [
      {
        label: "Rating",
        data: [
          SelectStar(
            (Math.round(
              formData?.star_rating_page1?.construction_category?.value +
                mflvalue(formData?.mfl_calculation?.mfl_estimate_precentage) +
                formData?.star_rating_page1?.predominant_construction?.value
            ) /
              80) *
              100
          ),
          SelectStar(
            Math.round(
              (formData?.fire_detection_protection?.available_score / 30) * 100
            )
          ),
          SelectStar(
            Math.round(
              (formData?.management_practice?.available_score / 30) * 100
            )
          ),
          SelectStar(
            Math.round((formData?.special_hazard?.available_score / 20) * 100)
          ),
          SelectStar(
            Math.round(
              (formData?.natural_hazards_exposure?.available_score / 40) * 100 +
                (formData?.natural_hazards_recommendation?.available_score /
                  40) *
                  100
            )
          ),
        ],
        backgroundColor: "rgba(166, 133, 0)",
      },
    ],
  };

  // Define scores here
  const roof_predominant_construction = Math.round(
    (formData?.star_rating_page1?.predominant_construction.value / 80) * 100
  );
  const naturalHazardsExposureScore = Math.round(
    (formData?.natural_hazards_exposure?.available_score / 40) * 100
  );
  const naturalHazardsRecommendationScore = Math.round(
    (formData?.natural_hazards_recommendation?.available_score / 40) * 100
  );
  const specialHazardScore = Math.round(
    (formData?.special_hazard?.available_score / 20) * 100
  );
  const managementPracticeScore = Math.round(
    (formData?.management_practice?.available_score / 30) * 100
  );
  const fireDetectionProtectionScore = Math.round(
    (formData?.fire_detection_protection?.available_score / 30) * 100
  );
  const star1construction_category = Math.round(
    (formData?.star_rating_page1?.construction_category?.value / 80) * 100
  );

  const total_available_s =
    formData?.star_rating_page1?.construction_category?.value +
    mflvalue(formData?.mfl_calculation?.mfl_estimate_precentage) +
    formData?.star_rating_page1?.predominant_construction?.value +
    formData?.natural_hazards_exposure?.available_score;

  const current_score =
    ((formData?.fire_detection_protection?.available_score +
      formData?.management_practice?.available_score +
      formData?.special_hazard?.available_score +
      formData?.natural_hazards_recommendation?.available_score) /
      100) *
    total_available_s;

  useEffect(() => {
    setOverallScore(SelectStar((current_score / total_available_s) * 100));
    setOccupancyStar(
      SelectStar(star1construction_category + roof_predominant_construction)
    );
    setFire_d_p_Star(SelectStar(fireDetectionProtectionScore));
    setManagementStar(SelectStar(managementPracticeScore));
    setSpecialStar(SelectStar(specialHazardScore));
    setNaturalStar(
      SelectStar(
        naturalHazardsExposureScore + naturalHazardsRecommendationScore
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    current_score,
    total_available_s,
    setOverallScore,
    setOccupancyStar,
    setFire_d_p_Star,
    setManagementStar,
    setSpecialStar,
    setNaturalStar,
  ]);

  const options = {
    indexAxis: "y", // Switch to a horizontal bar chart
    scales: {
      x: {
        beginAtZero: true,
        max: 5, // Maximum value for the x-axis
        grid: {
          display: true, // Display grid lines for the x-axis
          color: "#eeeeee", // Customize color for x-axis grid lines
          drawBorder: false, // Hide the x-axis border line
          drawOnChartArea: true, // Show the grid lines on the chart area
        },
        ticks: {
          callback: function (value) {
            return value === 0 ? "" : value + " Stars"; // Append 'Stars' to each tick value
          },
          color: "#999999",
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Hide grid lines for the y-axis
          drawBorder: false, // Hide the y-axis border line
        },
        ticks: {
          color: "#999999",
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
      datalabels: {
        display: false, // Disable any data labels that might be shown
      },
    },
    barThickness: 12, // Control bar thickness
    layout: {
      padding: {
        top: 30, // Adjust as needed
        bottom: 30, // Adjust as needed
      },
    },
  };
  const roundedScore = Math.round(overallScore);
  const filledStars = Math.floor(roundedScore);
  const emptyStars = 5 - filledStars;

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        item
        xs={12}
        sx={{
          boxShadow: "2rem",
          justifyContent: "space-between",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <img
          src={Cutout}
          alt="cutout"
          width={"101%"}
          style={{ justifyItems: "center" }}
        />

        <Grid
          container
          item
          xs={11.25}
          sx={{
            position: "absolute",
            justifyContent: "space-between",
            alignItems: "center",
            bottom: 0,
            left: { lg: "2rem", md: "1rem", sm: "1rem", xs: "1rem" },
          }}
        >
          <Grid
            container
            item
            xs={4.5}
            sx={{
              objectFit: "cover",
              justifyContent: "flex-start",
              alignContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "center", alignContent: "center" }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xl: "6rem",
                    lg: "5.5rem",
                    md: "3.5rem",
                    sm: "3rem",
                    xs: "3rem",
                  },
                  paddingTop: "1rem",
                  lineHeight: 1,
                  fontWeight: "bold",
                  color: "#22345B",
                }}
              >
                {roundedScore}
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={1}>
                {[...Array(filledStars)].map((_, index) => (
                  <img
                    key={`filled-star-${index}`}
                    src={Star}
                    alt="Filled Star"
                    style={{ width: "3.3rem", height: "3.5rem" }}
                  />
                ))}
                {[...Array(emptyStars)].map((_, index) => (
                  <img
                    key={`empty-star-${index}`}
                    src={Outline_Star}
                    alt="Empty Star"
                    style={{ width: "3.3rem", height: "3.5rem" }}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={7.5}
            sx={{
              objectFit: "cover",
              justifyContent: "flex-end",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                width: {
                  xl: "100%", // Keep 70% width for xl screens
                  lg: "100%", // Set full width for lg screens
                  md: "100%", // Set full width for md screens
                  sm: "100%", // Set full width for sm screens
                  xs: "90%", // Keep 90% width for xs screens
                },
                height: {
                  xl: "265px", // Keep 70% width for xl screens
                  lg: "255px", // Set full width for lg screens
                  md: "255px", // Set full width for md screens
                  sm: "255px", // Set full width for sm screens
                  xs: "255px", // Keep 90% width for xs screens
                },
                display: "flex",
                justifyContent: "flex-end",
                alignContent: "center",
              }}
            >
              <Bar options={options} data={data} />
            </Box>
            {/* <Box
              sx={{
                width: {
                  xl: "70%",
                  lg: "80%",
                  md: "90%",
                  sm: "90%",
                  xs: "90%",
                },
                height: "220px",
              }}
            >
              <Bar options={options} data={data} />
            </Box> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
