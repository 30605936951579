import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const hazardType = ["Yes", "Partial", "No"];

export default function ExternalExposures({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();

  const [hazardType1, setHazardType1] = useState("");
  const [hazardType2, setHazardType2] = useState("");
  const [hazardType3, setHazardType3] = useState("");
  const [hazardType4, setHazardType4] = useState("");

  const [quillbar1, setQuillbar1] = useState("");
  const [quillbar2, setQuillbar2] = useState("");
  const [quillbar3, setQuillbar3] = useState("");
  const [quillbar4, setQuillbar4] = useState("");

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/external_exposures/${state.id}`, {
        hazard1: hazardType1,
        comments1: quillbar1,
        hazard2: hazardType2,
        comments2: quillbar2,
        hazard3: hazardType3,
        comments3: quillbar3,
        hazard4: hazardType4,
        comments4: quillbar4,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "External exposures saved successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "External exposures saved successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "External exposures already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "External exposures already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "External exposures already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "External exposures already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "External exposures already exists!",
            }),
          5000
        );
      }
    }
  };

  const get = async (id) => {
    try {
      const { data } = await axios.get(`/assesment/${state.id}`);
      setHazardType1(data.external_exposures.north.hazard1);
      setHazardType2(data.external_exposures.east.hazard2);
      setHazardType3(data.external_exposures.south.hazard3);
      setHazardType4(data.external_exposures.west.hazard4);
      setQuillbar1(data.external_exposures.north.comments1);
      setQuillbar2(data.external_exposures.east.comments2);
      setQuillbar3(data.external_exposures.south.comments3);
      setQuillbar4(data.external_exposures.west.comments4);
    } catch (error) {}
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">External Exposures</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                      <p className="inputTextH">Detection</p>{" "}
                    </Grid>

                    <Grid item xs={3}>
                      <p className="inputTextH">Hazard</p>{" "}
                    </Grid>
                    <Grid item xs={7}>
                      <p className="inputTextH">Comment</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                      <p className="inputTextP">North</p>{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        hiddenLabel
                        id="hazard1"
                        name="hazard1"
                        size="small"
                        value={hazardType1}
                        options={hazardType}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                          setHazardType1(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={7} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar1}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar1}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                      <p className="inputTextP">South</p>{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        hiddenLabel
                        id="hazard3"
                        name="hazard3"
                        size="small"
                        value={hazardType3}
                        options={hazardType}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                          setHazardType3(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={7} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar3}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar3}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                      <p className="inputTextP">East</p>{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        hiddenLabel
                        id="hazard2"
                        name="hazard2"
                        size="small"
                        value={hazardType2}
                        options={hazardType}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                          setHazardType2(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={7} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar2}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                      <p className="inputTextP">West</p>{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        hiddenLabel
                        id="hazard4"
                        name="hazard4"
                        size="small"
                        value={hazardType4}
                        options={hazardType}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                          setHazardType4(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={7} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar4}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar4}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} spacing={1}>
                    <pre>
                      {JSON.stringify(formData.external_exposures, null, 2)}
                    </pre>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      onClick={() => handleClick()}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      disabled={
                        !isValid ||
                        callingBackend ||
                        !hazardType1 ||
                        !hazardType2 ||
                        !hazardType3 ||
                        !hazardType4 ||
                        hazardType1 === null ||
                        hazardType1 === undefined ||
                        hazardType2 === null ||
                        hazardType2 === undefined ||
                        hazardType3 === null ||
                        hazardType3 === undefined ||
                        hazardType4 === null ||
                        hazardType4 === undefined ||
                        quillbar1 === "<p><br></p>" ||
                        quillbar2 === "<p><br></p>" ||
                        quillbar3 === "<p><br></p>" ||
                        quillbar4 === "<p><br></p>" ||
                        !quillbar1 ||
                        !quillbar2 ||
                        !quillbar3 ||
                        !quillbar4
                      }
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
