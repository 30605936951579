import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function Data({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
}) {
  const [totaldata, setTotalData] = useState(null);

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  useEffect(() => {
    setTotalData(formData);
  }, [formData]);
  
  if (!totaldata) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container xs={10} spacing={1}>
          <Grid item xs={12} spacing={1}>
            <h1>Data</h1>
          </Grid>
          <Grid item xs={12} spacing={1}>
            <h2>Liability</h2>
            <div styles={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(totaldata.liability)}
            </div>
          </Grid>
          <Grid item xs={12} spacing={1}>
            <h2>MFL</h2>
            <div styles={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(totaldata.mfl_calculation)}
            </div>
          </Grid>
          <Grid item xs={12} spacing={1}>
            <h2>NLE</h2>
            <div styles={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(totaldata.nle_calculation)}
            </div>
          </Grid>
          <Grid item xs={12} spacing={1}>
            <h2>WLE</h2>
            <div styles={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(totaldata.wle_calculation)}
            </div>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sx={{
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              onClick={() => handleClick()}
              disabled={previouscallingBackend}
            >
              {previouscallingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Previous"
              )}
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={true}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
