import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const epsValues = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];
const apcValues = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];
const Types = [
  "Concrete",
  "Brick",
  "Stone",
  "Steel",
  "Wood",
  "Concrete/ Steel",
  "Brick/ Steel",
  "Stone/ Steel",
  "Concrete/ Wood",
  "Brick/ Wood",
  "Stone/ Wood",
  "EPS",
  "Other",
];
const constructionLabels = [
  {
    text: "Fire Resistive (reinforced concrete, brick, concrete block, brick veneer, stone)",
    element: (
      <p>
        <strong>Fire Resistive</strong>{" "}
        <em>
          (reinforced concrete, brick, concrete block, brick veneer, stone)
        </em>
      </p>
    ),
  },
  {
    text: "Non Combustible (steel on steel frame)",
    element: (
      <p>
        <strong>Non Combustible</strong> <em>(steel on steel frame)</em>
      </p>
    ),
  },
  {
    text: "Semi Combustible with no EPS (fire resistive or non combustible construction with some wood or other inferior construction materials)",
    element: (
      <p>
        <strong>Semi Combustible with no EPS</strong>{" "}
        <em>
          (fire resistive or non combustible construction with some wood or
          other inferior construction materials)
        </em>
      </p>
    ),
  },
  {
    text: "Mixed with No EPS (fire resistive with wood and other inferior construction)",
    element: (
      <p>
        <strong>Mixed with No EPS</strong>{" "}
        <em>(fire resistive with wood and other inferior construction)</em>
      </p>
    ),
  },
  {
    text: "Mixed with EPS (fire resistive with wood and other inferior construction with less than 25% EPS)",
    element: (
      <p>
        <strong>Mixed with EPS</strong>{" "}
        <em>
          (fire resistive with wood and other inferior construction with less
          than 25% EPS)
        </em>
      </p>
    ),
  },
  {
    text: "Combustible (less than 25% EPS)",
    element: (
      <p>
        <strong>Combustible</strong> <em>(less than 25% EPS)</em>
      </p>
    ),
  },
  {
    text: "Highly Combustible (greater than 25% EPS)",
    element: (
      <p>
        <strong>Highly Combustible</strong> <em>(greater than 25% EPS)</em>
      </p>
    ),
  },
];
export default function Construction({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();

  const inputArr = [
    {
      id: 0,
      name: "",
      level: "",
      area: "",
      construction_category: "",
      roof: "",
      floor: "",
      internal_wall: "",
      external_wall: "",
      fram: "",
      eps: "",
      apc: "",
      sprinklers: {},
      comment: "",
      total_adequate_percentage: 0,
    },
  ];
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const inputs = formData?.construction_page?.construction_type?.length
    ? [...formData?.construction_page?.construction_type]
    : inputArr;
  const num = formData?.construction_page?.construction_type?.length
    ? formData?.construction_page?.construction_type?.length
    : 1;
  const [arr, setArr] = useState(inputs);
  const [number, setNumber] = useState(num);
  const addInput = () => {
    setNumber(number + 1);
    setArr((s) => {
      return [
        ...s,
        {
          id: number,
          name: "",
          level: "",
          area: "",
          construction_category: "",
          roof: "",
          floor: "",
          internal_wall: "",
          external_wall: "",
          fram: "",
          eps: "",
          apc: "",
          sprinklers: {},
          comment: "",
          total_adequate_percentage: 0,
        },
      ];
    });
  };

  const submit = async () => {
    const areaTotal = arr?.reduce((n, { area }) => n + Number(area), 0);
    const newarr = arr?.map((item) => {
      const area_percentage = Math?.round((item?.area / areaTotal) * 100);
      console?.log("newarr", area_percentage);
      return {
        ...item,
        area_percentage: area_percentage,
      };
    });
    console?.log("newarrnewarr", newarr);

    try {
      setCallingBackend(true);
      await axios?.put(`/assesment/construction/${state?.id}`, {
        construction_type: newarr,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Construction page created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Construction page created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error?.response?.data === "Construction page already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Construction page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Construction page already exists!",
            }),
          5000
        );
      } else if (error?.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error?.response?.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Construction page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Construction page already exists!",
            }),
          5000
        );
      }
    }
  };
  const changeFormData = (value, name, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const changeCommentData = (value, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex]["comment"] = value;

    setArr([...arr]);
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Construction</p>
                <Grid container xs={10} spacing={1}>
                  {arr?.map((item, i) => {
                    return (
                      <>
                        <Grid container item xs={12} spacing={1}>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">Building</p>{" "}
                          </Grid>

                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">Levels</p>{" "}
                          </Grid>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">
                              Foot Print Area (m<sup>2</sup>)
                            </p>
                          </Grid>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">Roof</p>{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={3}>
                            <InputFeild
                              name="name"
                              value={item?.name}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "name",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                          <Grid item xs={3}>
                            <InputFeild
                              name="level"
                              value={item?.level}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "level",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                              type="number"
                            />{" "}
                          </Grid>
                          <Grid item xs={3}>
                            <InputFeild
                              name="area"
                              value={item?.area}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "area",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                              type="number"
                            />{" "}
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="roof"
                              name="roof"
                              size="small"
                              value={item.roof}
                              options={Types}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "roof", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">Floor</p>{" "}
                          </Grid>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">Internal Walls</p>{" "}
                          </Grid>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">External Walls</p>{" "}
                          </Grid>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">Frame</p>{" "}
                          </Grid>
                          {/* <Grid item xs={3}>
                            <InputFeild
                              name="name"
                              value={item?.name}
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid> */}
                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="floor"
                              name="floor"
                              size="small"
                              value={item.floor}
                              options={Types}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "floor", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="internal_wall"
                              name="internal_wall"
                              size="small"
                              value={item.internal_wall}
                              options={Types}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(
                                  newValue,
                                  "internal_wall",
                                  item.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="external_wall"
                              name="external_wall"
                              size="small"
                              value={item.external_wall}
                              options={Types}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(
                                  newValue,
                                  "external_wall",
                                  item.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="fram"
                              name="fram"
                              size="small"
                              value={item.fram}
                              options={Types}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "fram", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">EPS</p>{" "}
                          </Grid>
                          <Grid className="inputTextGrid" item xs={3}>
                            <p className="inputTextH">ACP</p>{" "}
                          </Grid>
                          <Grid className="inputTextGrid" item xs={6}>
                            <p className="inputTextH">Construction Category</p>{" "}
                          </Grid>

                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="eps"
                              name="eps"
                              size="small"
                              value={
                                item?.eps || {
                                  name: "",
                                  value: 0,
                                }
                              }
                              options={epsValues}
                              getOptionLabel={(option) => option?.name}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "eps", item?.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="apc"
                              name="apc"
                              size="small"
                              value={
                                item?.apc || {
                                  name: "",
                                  value: 0,
                                }
                              }
                              options={apcValues}
                              getOptionLabel={(option) => option?.name}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "apc", item?.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              hiddenLabel
                              id="construction_category"
                              name="construction_category"
                              size="small"
                              value={
                                constructionLabels.find(
                                  (option) =>
                                    option.text === item?.construction_category
                                ) || null
                              }
                              options={constructionLabels}
                              getOptionLabel={(option) => option.text}
                              onChange={(event, newValue) => {
                                changeFormData(
                                  newValue.text,
                                  "construction_category",
                                  item?.id
                                );
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.element}</li>
                              )}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                            ;
                          </Grid>
                          <Grid className="inputTextGrid" item xs={12}>
                            <p className="inputTextH">Description </p>{" "}
                          </Grid>
                          <Grid item xs={12} sx={{ marginBottom: "100px" }}>
                            <ReactQuill
                              modules={module}
                              theme="snow"
                              value={item?.comment}
                              style={{ marginBottom: "25px", height: "200px" }}
                              onChange={(e) => {
                                changeCommentData(e, item.id);
                              }}
                            />
                          </Grid>
                          {/* <Grid item xs={1.5}>
                            <InputFeild
                              name="sprinklers"
                              value={item?.sprinklers}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "sprinklers",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid> */}
                        </Grid>
                      </>
                    );
                  })}
                </Grid>

                <Grid container xs={10} spacing={1} paddingTop={"5rem"}>
                  <Button
                    onClick={addInput}
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      marginTop: "1.5rem",
                      marginLeft: "0?.5rem",
                    }}
                    disabled={
                      number >= 6 ||
                      !arr?.every(
                        (item) =>
                          !Object?.entries(item)?.some(
                            ([key, value]) => value === ""
                          )
                      )
                    }
                  >
                    Add Field
                  </Button>
                  <Grid item xs={12} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON?.stringify(formData?.construction_page, null, 2)}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        !arr?.every(
                          (item) =>
                            !Object?.entries(item)?.some(
                              ([key, value]) =>
                                value === "" ||
                                value === undefined ||
                                value === null
                            )
                        ) ||
                        number === 0 ||
                        callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert?.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert?.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert?.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
