import React from "react";
import { Grid } from "@mui/material";
import SprinklerReliability from "../Stepper3/SprinklerReliability";
import HydrantReliability from "../Stepper3/HydrantReliability";

export default function Chart({
  formData,
  getPage,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  return (
    <div>
      <div style={{ margin: "2rem 0" }}>
        {!formData?.assessment?.hysrant_water_supply_exclude && (
          <Grid
            container
            xs={12}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Grid
              container
              xs={10}
              sx={{ alignItems: "flex-start", justifyContent: "center" }}
            >
              <Grid item xs={12}>
                <HydrantReliability
                  callingBackend={callingBackend}
                  setCallingBackend={setCallingBackend}
                  getPage={getPage}
                  handleNextPage={handleNextPage}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
      <div style={{ margin: "2rem 0" }}>
        {!formData?.assessment?.water_supply_exclude && (
          <Grid
            container
            xs={12}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Grid
              container
              xs={10}
              sx={{ alignItems: "flex-start", justifyContent: "center" }}
            >
              <Grid item xs={12}>
                <SprinklerReliability
                  handlePreviousPage={handlePreviousPage}
                  previouscallingBackend={previouscallingBackend}
                  setPreviousCallingBackend={setPreviousCallingBackend}
                  handleNextPage={handleNextPage}
                  callingBackend={callingBackend}
                  setCallingBackend={setCallingBackend}
                  formData={formData}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}
