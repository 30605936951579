import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function SiteSecurity({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [otherName, setOtherName] = useState(formData?.site_security?.otherName ? formData?.site_security?.otherName : "Other");
  const [quillbar1, setQuillbar1] = useState("");
  const [quillbar2, setQuillbar2] = useState("");
  const [quillbar3, setQuillbar3] = useState("");
  const [quillbar4, setQuillbar4] = useState("");
  const [quillbar5, setQuillbar5] = useState("");
  const [quillbar6, setQuillbar6] = useState("");
  const [quillbar7, setQuillbar7] = useState("");
  const [quillbar8, setQuillbar8] = useState("");

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/site_security/${state.id}`, {
        valuable_commodities: quillbar1,
        yard_storage: quillbar2,
        gates_fencing_barriers: quillbar3,
        lighting: quillbar4,
        cctv: quillbar5,
        security_patrols: quillbar6,
        alarms: quillbar7,
        otherName: otherName,
        other: quillbar8,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Site security saved successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Site security saved successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Site security already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Site security already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Site security already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Site security already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Site security already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  useEffect(() => {
    setQuillbar1(formData?.site_security?.valuable_commodities);
    setQuillbar2(formData?.site_security?.yard_storage);
    setQuillbar3(formData?.site_security?.gates_fencing_barriers);
    setQuillbar4(formData?.site_security?.lighting);
    setQuillbar5(formData?.site_security?.cctv);
    setQuillbar6(formData?.site_security?.security_patrols);
    setQuillbar7(formData?.site_security?.alarms);
    setQuillbar8(formData?.site_security?.other);

  }, [formData]);
  return (
    <div>
      <Formik
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Site Security</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextH"></p>{" "}
                    </Grid>

                    <Grid item xs={9}>
                      <p className="inputTextH">Description</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Valuable Commodities</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar1}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar1}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Yard Storage</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar2}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Gates/Fencing/Barriers</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar3}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar3}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Lighting</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar4}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar4}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">CCTV</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar5}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Security Patrols</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar6}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar6}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Alarms</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar7}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar7}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <InputFeild
                        name="otherName"
                        value={otherName}
                        onChange={(e) => setOtherName(e?.target?.value)}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar8}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar8}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={6} spacing={1}>
                    <pre>
                      {JSON.stringify(formData?.site_security, null, 2)}
                    </pre>
                  </Grid> */}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      disabled={
                        !quillbar1 ||
                        !quillbar2 ||
                        !quillbar3 ||
                        !quillbar4 ||
                        !quillbar5 ||
                        !quillbar6 ||
                        !quillbar7 ||
                        quillbar1 === "<p><br></p>" ||
                        quillbar2 === "<p><br></p>" ||
                        quillbar3 === "<p><br></p>" ||
                        quillbar4 === "<p><br></p>" ||
                        quillbar5 === "<p><br></p>" ||
                        quillbar6 === "<p><br></p>" ||
                        quillbar7 === "<p><br></p>" ||
                        callingBackend
                      }
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
