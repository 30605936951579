import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import axios from "../../libs/axios";

import Drawer from "@mui/material/Drawer";
import { Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { useLocation } from "react-router-dom";

// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";

export default function TemporaryDrawer({
  page,
  getPage,
  formData,
  setCallingBackend,
}) {
  const [open, setOpen] = React.useState(false);
;
const [filteredSteps,setFilteredSteps] = useState([])
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const { state } = useLocation();

  // eslint-disable-next-line
  const [pageNum, setPageNum] = useState(page);

const steps = [
  { formNo: 1, name: "Aditional Assessment Details" },
  { formNo: 2, name: "Operational Overview" },
  { formNo: 3, name: "Construction" },
  { formNo: 4, name: "Overall Construction Classification" },
  { formNo: 5, name: "Construction RI" },
  { formNo: 6, name: "Occupancy" },
  { formNo: 7, name: "Fire Area" },
  { formNo: 8, name: "Special Hazards" },
  { formNo: 9, name: "Special Hazards RI" },
  { formNo: 10, name: "Essential Services" },
  { formNo: 11, name: "Sprinkler Systems" },
  { formNo: 12, name: "Sprinkler System Demands" },
  { formNo: 13, name: "Hydrant Systems" },
  { formNo: 14, name: "Towns Main Water Supply" },
  { formNo: 15, name: "Pump 1 Water Supply" },
  { formNo: 16, name: "Pump 2 Water Supply" },
  { formNo: 17, name: "Pump 3 Water Supply" },
  { formNo: 18, name: "Pump 4 Water Supply" },
  { formNo: 19, name: "Fire Detection" },
  { formNo: 20, name: "Other Fire Response" },
  { formNo: 21, name: "Fire Detection and Protection" },
  { formNo: 22, name: "Fire Detection and Protection RI" },
  { formNo: 23, name: "Management Practices" },
  { formNo: 24, name: "Management Practices RI" },
  { formNo: 25, name: "Natural Hazards Exposure" },
  { formNo: 26, name: "Natural Hazards Recommendation" },
  { formNo: 27, name: "Natural Hazards RI" },
  { formNo: 28, name: "External Exposures" },
  { formNo: 29, name: "Site Security" },
  { formNo: 30, name: "Site Photographs" },
  { formNo: 31, name: "Basic Details" },
  { formNo: 32, name: "Sublimits Of Liability" },
  { formNo: 33, name: "Maximum Foreseable Loss (MFL)" },
  { formNo: 34, name: "Normal Loss Expectancy (NLE)" },
  { formNo: 35, name: "WLE Sublimits Of Liability" },
  { formNo: 36, name: "Windstorm Loss Expectancy (WLE)" },
  { formNo: 37, name: "Special Hazard RI" },
  { formNo: 38, name: "Risk Improvement Priority" },
  { formNo: 39, name: "RI Rearrange" },
  { formNo: 40, name: "Executive Summary" },
  { formNo: 41, name: "Additional Details" },
  { formNo: 42, name: "PDF" },
  { formNo: 43, name: "Data" },
];
  const handlePageNumber = async (fNo) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: fNo,
      });
      setTimeout(() => getPage(), 1000);
    } catch (error) {
    } finally {
      setCallingBackend(false);
    }
  };
  useEffect(() => {
    const fillter =
      formData?.assessment?.wle_exclude &&
      formData?.assessment?.water_supply_exclude &&
      formData?.assessment?.hysrant_water_supply_exclude
        ? steps.filter(
            (step) =>
              step.formNo !== 18 && step.formNo !== 31 && step.formNo !== 32
          )
        : formData?.assessment?.wle_exclude &&
          !formData?.assessment?.water_supply_exclude &&
          !formData?.assessment?.hysrant_water_supply_exclude
        ? steps.filter((step) => step.formNo !== 31 && step.formNo !== 32)
        : formData?.assessment?.wle_exclude &&
          !formData?.assessment?.water_supply_exclude &&
          formData?.assessment?.hysrant_water_supply_exclude
        ? steps.filter((step) => step.formNo !== 31 && step.formNo !== 32)
        : formData?.assessment?.wle_exclude &&
          formData?.assessment?.water_supply_exclude &&
          !formData?.assessment?.hysrant_water_supply_exclude
        ? steps.filter((step) => step.formNo !== 31 && step.formNo !== 32)
        : !formData?.assessment?.wle_exclude &&
          formData?.assessment?.water_supply_exclude &&
          formData?.assessment?.hysrant_water_supply_exclude
        ? steps.filter((step) => step.formNo !== 18)
        : !formData?.assessment?.wle_exclude &&
          !formData?.assessment?.water_supply_exclude &&
          formData?.assessment?.hysrant_water_supply_exclude
        ? steps
        : !formData?.assessment?.wle_exclude &&
          formData?.assessment?.water_supply_exclude &&
          !formData?.assessment?.hysrant_water_supply_exclude
        ? steps
        : steps;

    setFilteredSteps(fillter);
  }, [
    formData?.assessment?.wle_exclude,
    formData?.assessment?.water_supply_exclude,
    formData?.assessment?.hysrant_water_supply_exclude,
    setFilteredSteps,
  ]);
  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {filteredSteps.map((text, index) => (
          <ListItem key={text.formNo} disablePadding>
            <ListItemButton
              onClick={() => {
                handlePageNumber(text.formNo);
              }}
              style={{
                backgroundColor: text?.formNo === page ? "#eeeeee" : "#ffffff",
              }}
              disabled={text?.formNo > page ? true : false}
            >
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)} sx={{ color: "#000" }}>
        <MenuRoundedIcon />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
