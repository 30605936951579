import React from "react";
import { Field, ErrorMessage } from "formik";
// import { makeStyles } from "@mui/styles";
import "./Feild.css";

import TextField from "@mui/material/TextField";
export default function InputFeild({
  label = "Email",
  name = "name",
  bgColor = "#fff",
  Placeholder,
  defaultValue,
  rows = 1,
  multiline,
  type = "text",
  height,
  disabled,
}) {
  // const useStyles = makeStyles(() => ({
  //   text: {
  //     width: "100%",
  //     border: "none",
  //     padding: '0.5rem',
  //     color: "#41630F",
  //     "&:onclick": {
  //       border: "1px solid #41630F",
  //     },
  //   },
  // }));
  // const classes = useStyles();

  // class="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputSizeSmall MuiInputBase-inputHiddenLabel css-mps6pm-MuiInputBase-input-MuiFilledInput-input"
  return (
    <div>
      <Field
        sx={{
          width: "100%",
          fontFamily: "serif",
        }}
        as={TextField}
        hiddenLabel
        size="small"
        id="filled-hidden-label-small"
        defaultValue={defaultValue}
        placeholder={Placeholder}
        fullWidth
        name={name}
        helperText={<ErrorMessage name={name}></ErrorMessage>}
        rows={rows}
        type={type}
        multiline={multiline}
        disabled={disabled}
        InputProps={{ inputProps: { min: 1 } }}
      ></Field>
    </div>
  );
}
