import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/TextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { validationSchema } from "../../utils/validations/operationalOverview";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'


export default function OperationalOverview({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();

  const [initialValues, setInitialValues] = useState({
    number_of_employees: "",
    days_per_week: "",
    seasonality: "",
    description: "",
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [quillbar, setQuillbar] = useState(
    formData?.operational_overview?.description
    || '');
  const [quillbar1, setQuillbar1] = useState(
    formData?.operational_overview?.number_of_employees || ""
  );
  const [quillbar2, setQuillbar2] = useState(
    formData?.operational_overview?.days_per_week || ""
  );
  const [quillbar3, setQuillbar3] = useState(
    formData?.operational_overview?.seasonality || ""
  );
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [
      { list: "ordered" },
      { list: "bullet" },
      { list: "check" },
    ],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/operational-overview/${state.id}`, {
        number_of_employees: quillbar1,
        days_per_week: quillbar2,
        description: quillbar,
        seasonality: quillbar3,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Operational Overview created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Operational Overview created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Operational Overview already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Operational Overview already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Operational Overview already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Operational Overview already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Operational Overview already exists!",
            }),
          5000
        );
      }
    }
  };

  useEffect(() => {
    setInitialValues({
      number_of_employees: formData?.operational_overview?.number_of_employees,
      days_per_week: formData?.operational_overview?.days_per_week,
      seasonality: formData?.operational_overview?.seasonality,
      description: formData?.operational_overview?.description,
    });
    setQuillbar(formData?.operational_overview?.description);
       setQuillbar1(formData?.operational_overview?.number_of_employees);
       setQuillbar2(formData?.operational_overview?.days_per_week);
       setQuillbar3(formData?.operational_overview?.seasonality);
  }, [formData]);
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Operational Overview</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={12}>
                    <p className="inputTextH">Operational Activity</p>
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                    <p className="inputTextP">Number Of Employees</p>
                    <ReactQuill
                      modules={module}
                      theme="snow"
                      value={quillbar1}
                      style={{ marginBottom: "25px", height: "75px" }}
                      onChange={setQuillbar1}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                    <p className="inputTextP">Days Per Week</p>
                    <ReactQuill
                      modules={module}
                      theme="snow"
                      value={quillbar2}
                      style={{ marginBottom: "25px", height: "75px" }}
                      onChange={setQuillbar2}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                    <p className="inputTextP">Seasonality</p>
                    <ReactQuill
                      modules={module}
                      theme="snow"
                      value={quillbar3}
                      style={{ marginBottom: "25px", height: "75px" }}
                      onChange={setQuillbar3}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop={"3rem"}>
                    <p className="inputTextH">Description</p>
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                    <ReactQuill
                      modules={module}
                      theme="snow"
                      value={quillbar}
                      style={{ marginBottom: "25px", height: "200px" }}
                      onChange={setQuillbar}
                    />
                  </Grid>
                  <Grid item xs={12} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(quillbar, null, 2)}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        quillbar1 === "<p><br></p>" ||
                        quillbar2 === "<p><br></p>" ||
                        quillbar3 === "<p><br></p>" ||
                        !quillbar1 ||
                        !quillbar2 ||
                        !quillbar3 ||
                        callingBackend ||
                        !quillbar
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
