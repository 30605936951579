import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Autocomplete from "@mui/material/Autocomplete";

const windowtype = [
  { name: "Plasterboard Factor", value: 0.35 },
  { name: "Reinforced Concrete Factor", value: 0.1 },
];
const rooftype = [
  { name: "No Roof Damage", value: 0 },
  { name: "Damage to Corners", value: 400 },
  { name: "Damage to Corners and Perimeter", value: 600 },
  { name: "Loss of Entire Roof", value: 1000 },
];
export default function WLEPage2({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const miscellaneous = formData?.wle_calculation?.miscellaneous_damage?.length
    ? formData?.wle_calculation?.miscellaneous_damage
    : [
        {
          id: 0,
          name: "Replacement of Windows",
          value: 0,
        },
        {
          id: 1,
          name: "Replacement of Dock Doors",
          value: 0,
        },
        {
          id: 2,
          name: "Replacement of Roof",
          value: 0,
        },
        {
          id: 3,
          name: "Replacement of Mounted Equipment",
          value: 0,
        },
        {
          id: 4,
          name: "Other - Editable Label",
          value: 0,
        },
        {
          id: 5,
          name: "Other - Editable Label",
          value: 0,
        },
        {
          id: 6,
          name: "Other - Editable Label",
          value: 0,
        },
        {
          id: 7,
          name: "Other - Editable Label",
          value: 0,
        },
        {
          id: 8,
          name: "Other - Editable Label",
          value: 0,
        },
      ];
  const section1 = [
    {
      id: 0,
      name: "Building",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 1,
      name: "Contents",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 2,
      name: "Stocks",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 3,
      name: "Other",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 4,
      name: "Other",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 5,
      name: "Other",
      fire_area: 0,
      damages: 0,
    },
  ];
  const section2 = formData?.wle_calculation?.section2.length
    ? [...formData?.wle_calculation?.section2]
    : [
        {
          id: 0,
          name: "Phase 1",
          of_BI: 0,
          months: 0,
          affected: 0,
        },
        {
          id: 1,
          name: "Phase 2",
          of_BI: 0,
          months: 0,
          affected: 0,
        },
      ];

  const windowinputArr = formData?.wle_calculation?.section1_window?.length
    ? formData?.wle_calculation?.section1_window
    : [
        {
          id: 0,
          width: 0,
          height: 0,
          area: 0,
        },
      ];
  const [windowArr, setWindowArr] = useState(windowinputArr);
  const [windownumber, setWindowNumber] = useState(
    formData?.wle_calculation?.section1_window?.length
      ? formData?.wle_calculation?.section1_window?.length
      : 1
  );
  const windowobj = formData?.wle_calculation?.window_meterial
    ? formData?.wle_calculation?.window_meterial
    : {
        name: "",
        value: 0,
      };

  const doorobj = formData?.wle_calculation?.door_meterial
    ? formData?.wle_calculation?.door_meterial
    : {
        name: "",
        value: 0,
      };

  const roofobj = formData?.wle_calculation?.roof_meterial
    ? formData?.wle_calculation?.roof_meterial
    : {
        name: "",
        value: 0,
      };
  const roofdamageobj = formData?.wle_calculation?.roof_damage
    ? formData?.wle_calculation?.roof_damage
    : {
        name: "",
        value: 0,
      };
  const [windowvalue, setWindowValue] = useState(windowobj);
  const [doorvalue, setDoorValue] = useState(doorobj);
  const [roofvalue, setRoofValue] = useState(roofobj);
  const [roofDamagevalue, setRoofdamageValue] = useState(roofdamageobj);
  const [miscellaneousarr, setMiscellaneousarr] = useState(miscellaneous);

  const addWindowInput = () => {
    setWindowNumber(windownumber + 1);
    setWindowArr((s) => {
      return [
        ...s,
        {
          id: windownumber,
          width: 0,
          height: 0,
          area: 0,
        },
      ];
    });
  };
  const doorinputArr = formData?.wle_calculation?.section1_door?.length
    ? formData?.wle_calculation?.section1_door
    : [
        {
          id: 0,
          width: 0,
          height: 0,
          area: 0,
        },
      ];
  const [doorArr, setDoorArr] = useState(doorinputArr);
  const [doornumber, setDoorNumber] = useState(
    formData?.wle_calculation?.section1_door?.length
      ? formData?.wle_calculation?.section1_door?.length
      : 1
  );

  const addDoorInput = () => {
    setDoorNumber(doornumber + 1);
    setDoorArr((s) => {
      return [
        ...s,
        {
          id: doornumber,
          width: 0,
          height: 0,
          area: 0,
        },
      ];
    });
  };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const changeWindowFormData = (value, name, id) => {
    const objIndex = windowArr.findIndex((obj) => obj.id === id);
    windowArr[objIndex][name] = value;

    setWindowArr([...windowArr]);
  };
  const changeDoorFormData = (value, name, id) => {
    const objIndex = doorArr.findIndex((obj) => obj.id === id);
    doorArr[objIndex][name] = value;

    setDoorArr([...doorArr]);
  };
  const Area = formData?.construction_page?.construction_type?.reduce(
    (total, item) => total + item.area,
    0
  );
  const { state } = useLocation();
const valuepersquare = formData?.basic_details?.section1
  ?.map((item) => {
    if (item.name !== "Other" || (item.name === "Other" && item.value !== 0)) {
      return {
        ...item,
        value_per_square: Number(Math.floor(item?.value / Area)),
      };
    } else {
      return null;
    }
  })
  .filter((item) => item !== null);
  console.log("valuepersquare", valuepersquare);
  const [arrsec2, setArrsec2] = useState(section2);
  const bimonth = formData?.basic_details?.bi_over_ip
    ? formData?.basic_details?.bi_over_ip
    : 0;
  const wlesublimits = formData?.wleliability?.wle_edit
    ? formData?.wleliability?.total_of_wle
    : formData?.liability?.total_of_wle;

  const [quillbar, setQuillbar] = useState(
    formData?.wle_calculation?.comment || ""
  );
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const changeFormData = (value, name, id) => {
    const objIndex = miscellaneousarr?.findIndex((obj) => obj?.id === id);
    miscellaneousarr[objIndex][name] = value;

    setMiscellaneousarr([...miscellaneousarr]);
  };

  const changeFormDataSec2 = (value, name, id) => {
    const objIndex = arrsec2?.findIndex((obj) => obj?.id === id);
    arrsec2[objIndex][name] = value;

    setArrsec2([...arrsec2]);
  };

  const submit = async (e, { resetForm }) => {
    const windowarrtotal = windowArr?.map((item, idx) => {
      return {
        ...item,
        area: Number(item?.width) * Number(item?.height) * 2.5,
      };
    });
    const doorarrtotal = doorArr?.map((item, idx) => {
      return {
        ...item,
        area: Number(item?.width) * Number(item?.height) * 15,
      };
    });

    const calculatedwindow = windowarrtotal.map((bItem) => {
      const calculatedValues = valuepersquare.map(
        (aItem) => bItem.area * (aItem?.value / Area) * windowvalue.value
      );
      const calculateV = calculatedValues?.reduce((n, m) => n + m, 0);
      return Math.floor(calculateV);
    });
    const calculatedwindowtatal = calculatedwindow?.reduce((n, m) => n + m, 0);

    const calculateddoor = doorarrtotal.map((bItem) => {
      const calculatedValues = valuepersquare.map(
        (aItem) => bItem.area * (aItem?.value / Area) * doorvalue.value
      );
      const calculateV = calculatedValues?.reduce((n, m) => n + m, 0);
      return Math.floor(calculateV);
    });
    const calculateddoortatal = calculateddoor?.reduce((n, m) => n + m, 0);

    const calculatedroof = valuepersquare.map((aItem) => {
      const calculateV =
        roofDamagevalue.value * (aItem?.value / Area) * roofvalue.value;
      return Math.floor(calculateV);
    });
    const calculatedrooftatal = calculatedroof?.reduce((n, m) => n + m, 0);
    const miscellaneoustotal = miscellaneousarr?.reduce(
      (n, { value }) => n + Number(value),
      0
    );

    console.log(
      "total",
      calculatedrooftatal +
        calculatedwindowtatal +
        calculateddoortatal +
        miscellaneoustotal
    );
    const totalwlebi = arrsec2?.reduce(
      (n, { of_BI, affected, months }) =>
        n +
        (Number(bimonth) * Number(of_BI) * Number(affected) * Number(months)) /
          10000,
      0
    );

    try {
      setCallingBackend(true);
      await axios.put(`/assesment/wle_calculation/${state.id}`, {
        section1_window: windowArr,
        section1_door: doorArr,
        roof_damage: roofDamagevalue,
        window_meterial: windowvalue,
        door_meterial: doorvalue,
        roof_meterial: roofvalue,
        miscellaneous_damage: miscellaneousarr,
        calculatedroof_total: calculatedrooftatal,
        calculatedwindow_total: calculatedwindowtatal,
        calculateddoor_total: calculateddoortatal,
        miscellaneous_total: miscellaneoustotal,
        section1_total:
          calculatedrooftatal +
          calculatedwindowtatal +
          calculateddoortatal +
          miscellaneoustotal,
        section2: arrsec2,
        comment: quillbar,
        total_of_wlefire: Math.floor(
          calculatedrooftatal +
            calculatedwindowtatal +
            calculateddoortatal +
            miscellaneoustotal
        ),
        total_of_wle_bi: Math.floor(totalwlebi),
        total_of_loss: Math.floor(
          calculatedrooftatal +
            calculatedwindowtatal +
            calculateddoortatal +
            miscellaneoustotal +
            totalwlebi
        ),
        wle_estimate: Math.floor(
          calculatedrooftatal +
            calculatedwindowtatal +
            calculateddoortatal +
            miscellaneoustotal +
            totalwlebi +
            wlesublimits
        ),
        wle_estimate_precentage: Math.floor(
          ((calculatedrooftatal +
            calculatedwindowtatal +
            calculateddoortatal +
            miscellaneoustotal +
            totalwlebi +
            wlesublimits) /
            formData?.basic_details?.total_s1_s2) *
            100
        ),
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "WLE calculation page created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "WLE calculation page created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "WLE calculation page already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "WLE calculation page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "WLE calculation page already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "WLE calculation page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "WLE calculation page already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  // useEffect(() => {
  //   if (formData?.wle_calculation?.section2?.length) {
  //     setArrsec2([...formData?.wle_calculation?.section2]);
  //     setArr([...formData?.wle_calculation?.section1]);
  //     setWleArr([...formData?.basic_details?.section1]);
  //     setBi(formData?.basic_details?.business_interuption);
  //     setBimonth(formData?.basic_details?.bi_over_ip);
  //     setWlesublimits(
  //       formData?.wleliability?.wle_edit
  //         ? formData?.wleliability?.total_of_wle
  //         : formData?.liability?.total_of_wle
  //     );
  //     setQuillbar(formData?.wle_calculation?.comment);
  //   }
  //   setSublimittotalfirearea(formData?.liability?.total_of_firearea);
  // }, [formData]);
  return (
    <div>
      <Formik
        initialValues={section1}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">
                  Windstorm Loss Expectancy (WLE) Calculation
                </p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={12}>
                      <p className="inputTextH">Property Damage (Section 1)</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={12}>
                      <p className="inputTextH">Value Per Square Metre</p>{" "}
                    </Grid>
                  </Grid>
                  {valuepersquare.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={6}>
                          <p>{item?.name}</p>
                        </Grid>
                        <Grid item xs={6}>
                          <p>= {item?.value_per_square || 0}</p>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Grid container xs={10} spacing={1} mt={"3rem"}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={12}>
                      <p className="inputTextH">
                        Window Damage Loss Expectancy
                      </p>{" "}
                    </Grid>
                    <Grid item xs={4}>
                      <p>Construction Factor</p>{" "}
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        hiddenLabel
                        id="windowvalue"
                        name="windowvalue"
                        size="small"
                        value={
                          windowvalue || {
                            name: "",
                            value: 0,
                          }
                        }
                        options={windowtype}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, newValue) => {
                          setWindowValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    <Grid className="inputTextGrid" item xs={4}>
                      <p className="inputTextH">Name</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={4}>
                      <p className="inputTextH">Width</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={4}>
                      <p className="inputTextH">Height</p>{" "}
                    </Grid>
                  </Grid>
                  {windowArr.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={4}>
                          {" "}
                          <p>Window {item.id + 1}</p>
                        </Grid>
                        <Grid item xs={4}>
                          <InputFeild
                            name="width"
                            type="number"
                            value={item?.width}
                            onChange={(e) =>
                              changeWindowFormData(
                                e?.target?.value,
                                "width",
                                item?.id
                              )
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>
                        <Grid item xs={4}>
                          <InputFeild
                            name="height"
                            type="number"
                            value={item?.height}
                            onChange={(e) =>
                              changeWindowFormData(
                                e?.target?.value,
                                "height",
                                item?.id
                              )
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>
                      </>
                    );
                  })}
                  <Button
                    onClick={addWindowInput}
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      marginTop: "1.5rem",
                      marginLeft: "0?.5rem",
                    }}
                    disabled={
                      windownumber >= 10 ||
                      !windowArr?.every(
                        (item) =>
                          !Object?.entries(item)?.some(
                            ([key, value]) =>
                              item.width === 0 || item.height === 0
                          )
                      )
                    }
                  >
                    Add Field
                  </Button>
                </Grid>
                <Grid container xs={10} spacing={1} mt={"3rem"}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={12}>
                      <p className="inputTextH">Dock Doors Loss Expectancy</p>{" "}
                    </Grid>
                    <Grid item xs={4}>
                      <p>Construction Factor</p>{" "}
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        hiddenLabel
                        id="doorvalue"
                        name="doorvalue"
                        size="small"
                        value={
                          doorvalue || {
                            name: "",
                            value: 0,
                          }
                        }
                        options={windowtype}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, newValue) => {
                          setDoorValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    <Grid className="inputTextGrid" item xs={4}>
                      <p className="inputTextH">Name</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={4}>
                      <p className="inputTextH">Width</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={4}>
                      <p className="inputTextH">Height</p>{" "}
                    </Grid>
                  </Grid>
                  {doorArr.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={4}>
                          {" "}
                          <p>Dock Door {item.id + 1}</p>
                        </Grid>
                        <Grid item xs={4}>
                          <InputFeild
                            name="width"
                            type="number"
                            value={item?.width}
                            onChange={(e) =>
                              changeDoorFormData(
                                e?.target?.value,
                                "width",
                                item?.id
                              )
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>
                        <Grid item xs={4}>
                          <InputFeild
                            name="height"
                            type="number"
                            value={item?.height}
                            onChange={(e) =>
                              changeDoorFormData(
                                e?.target?.value,
                                "height",
                                item?.id
                              )
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>
                      </>
                    );
                  })}
                  <Button
                    onClick={addDoorInput}
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      marginTop: "1.5rem",
                      marginLeft: "0?.5rem",
                    }}
                    disabled={
                      doornumber >= 10 ||
                      !doorArr?.every(
                        (item) =>
                          !Object?.entries(item)?.some(
                            ([key, value]) =>
                              item.width === 0 || item.height === 0
                          )
                      )
                    }
                  >
                    Add Field
                  </Button>
                </Grid>
                <Grid container xs={10} spacing={1} mt={"3rem"}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={12}>
                      <p className="inputTextH">Roof Damage</p>{" "}
                    </Grid>
                    <Grid item xs={4}>
                      <p>Construction Factor</p>{" "}
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        hiddenLabel
                        id="roofvalue"
                        name="roofvalue"
                        size="small"
                        value={
                          roofvalue || {
                            name: "",
                            value: 0,
                          }
                        }
                        options={windowtype}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, newValue) => {
                          setRoofValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <p>Roof</p>{" "}
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        hiddenLabel
                        id="roofDamagevalue"
                        name="roofDamagevalue"
                        size="small"
                        value={
                          roofDamagevalue || {
                            name: "",
                            value: 0,
                          }
                        }
                        options={rooftype}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, newValue) => {
                          setRoofdamageValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={10} spacing={1} mt={"3rem"}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={12}>
                      <p className="inputTextH">Miscellaneous Damage</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={6}>
                      <p className="inputTextH">Name</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={6}>
                      <p className="inputTextH">$</p>{" "}
                    </Grid>
                  </Grid>
                  {miscellaneousarr.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={6}>
                          <InputFeild
                            name="name"
                            value={item?.name}
                            onChange={(e) =>
                              changeFormData(e?.target?.value, "name", item?.id)
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>
                        <Grid item xs={6}>
                          <InputFeild
                            name="value"
                            type="number"
                            value={item?.value}
                            onChange={(e) =>
                              changeFormData(
                                e?.target?.value,
                                "value",
                                item?.id
                              )
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <Formik
        onSubmit={submit}
        initialValues={section2}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={2}>
                      <p className="inputTextH">
                        Business Interruption (Section 2)
                      </p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">% Of BI</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">Months</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">% Affected</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={5.5}></Grid>
                  </Grid>
                  {arrsec2.map((item, i) => {
                    return (
                      <>
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={1}
                          style={{
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          <Grid className="inputTextGrid" item xs={2}>
                            <InputFeild
                              name="name"
                              value={item?.name}
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "name",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                          <Grid container item xs={1.5} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="of_BI"
                                type="number"
                                value={item?.of_BI}
                                onChange={(e) =>
                                  changeFormDataSec2(
                                    e?.target?.value,
                                    "of_BI",
                                    item?.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={1.5} spacing={1}>
                            <Grid item xs={12}>
                              <TextField
                                name="months"
                                value={item?.months}
                                type="number"
                                onChange={(e) => {
                                  changeFormDataSec2(
                                    e?.target?.value,
                                    "months",
                                    item?.id
                                  );
                                }}
                                InputProps={{
                                  inputProps: { step: "0.01", min: "0" },
                                }} // Allows decimals
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                style={{ backgroundColor: "#FFFFFF" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={1.5} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="affected"
                                value={item?.affected}
                                type="number"
                                onChange={(e) =>
                                  changeFormDataSec2(
                                    e?.target?.value,
                                    "affected",
                                    item?.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={5.5} spacing={1}></Grid>
                        </Grid>{" "}
                      </>
                    );
                  })}
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    style={{ paddingTop: "2rem" }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextH">WLE Scenario</p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                      {/* <InputFeild
                        name="comment"
                        component={TextField}
                        value={comment}
                        onChange={(e) => setComment(e?.target?.value)}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={10}
                      />{" "} */}
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar}
                        style={{ marginBottom: "25px", height: "300px" }}
                        onChange={setQuillbar}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(formData?.wle_calculation, null, 2)}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={!isValid || callingBackend}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
