import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function EssentialsService({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();
  const initialValues =
    formData?.essential_service?.power_supply
      ? {
          power_supply: formData?.essential_service?.power_supply,
          gas_supply: formData?.essential_service?.gas_supply,
          industrial_heating: formData?.essential_service?.industrial_heating,
          refrigeration: formData?.essential_service?.refrigeration,
          other: formData?.essential_service?.other,
        }
      : {
          power_supply: "",
          gas_supply: "",
          industrial_heating: "",
          refrigeration: "",
          other: "",
        };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [quillbar1, setQuillbar1] = useState(
    formData?.essential_service?.power_supply
      ? formData?.essential_service?.power_supply
      : ""
  );
  const [quillbar2, setQuillbar2] = useState(
    formData?.essential_service?.gas_supply
      ? formData?.essential_service?.gas_supply
      : ""
  );
  const [quillbar3, setQuillbar3] = useState(
    formData?.essential_service?.industrial_heating
      ? formData?.essential_service?.industrial_heating
      : ""
  );
  const [quillbar4, setQuillbar4] = useState(
    formData?.essential_service?.refrigeration
      ? formData?.essential_service?.refrigeration
      : ""
  );
  const [quillbar5, setQuillbar5] = useState(
    formData?.essential_service?.other ? formData?.essential_service?.other : ""
  );

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/essential_service/${state.id}`, {
        power_supply: quillbar1,
        gas_supply: quillbar2,
        industrial_heating: quillbar3,
        refrigeration: quillbar4,
        other: quillbar5,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Essentials services page created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Essentials services page created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Essentials services page already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Essentials services page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Essentials services page already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Essentials services page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Essentials services page already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Essentials Services</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                      <p className="inputTextP">Power Supply</p>{" "}
                    </Grid>
                    <Grid item xs={10} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar1}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar1}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                      <p className="inputTextP">Gas Supply</p>{" "}
                    </Grid>
                    <Grid item xs={10} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar2}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2}>
                      <p className="inputTextP">Industrial Heating</p>{" "}
                    </Grid>
                    <Grid item xs={10} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar3}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar3}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2} sx={{ marginBottom: "50px" }}>
                      <p className="inputTextP">Refrigeration</p>{" "}
                    </Grid>
                    <Grid item xs={10} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar4}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar4}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={2} sx={{ marginBottom: "50px" }}>
                      <p className="inputTextP">Other</p>{" "}
                    </Grid>
                    <Grid item xs={10}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar5}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar5}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        !quillbar1 ||
                        !quillbar2 ||
                        !quillbar3 ||
                        !quillbar4 ||
                        quillbar1 === "<p><br></p>" ||
                        quillbar2 === "<p><br></p>" ||
                        quillbar3 === "<p><br></p>" ||
                        quillbar4 === "<p><br></p>" ||
                        callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
