import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid } from "@mui/material";
import axios from "../../libs/axios";
import { useLocation } from "react-router-dom";
import ChartDataLabels from "chartjs-plugin-datalabels";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Registering the DataLabels plugin
);

export default function PumpsetChart({ text }) {
  const { state } = useLocation();
  const [waterSupply, setWaterSupply] = useState([{}]);
  const [pump1, setPump1] = useState([{}]);
  const [pump2, setPump2] = useState([{}]);
  const [pump3, setPump3] = useState([{}]);
  const [pump4, setPump4] = useState([{}]);
  const [activeDataset, setActiveDataset] = useState(null);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: () => "",
          label: (context) =>
            `Name: ${context.raw.name}, Value: (x:${context.parsed.x}, y:${context.parsed.y})`,
        },
      },
      legend: {
        onClick: (e, legendItem) => {
          const datasetLabel = legendItem.text;
          setActiveDataset((prevDataset) =>
            prevDataset === datasetLabel ? null : datasetLabel
          );
        },
      },
      datalabels: {
        display: true,
        align: "top",
        formatter: (value, context) => value.name,
        color: "black",
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Demand Flow (L/min)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Pressure (kPa)",
        },
      },
    },
  };

  const getDataSets = () => [
    {
      label: "Pump 1",
      data: pump1.map((item, index) => ({
        x: item.sprinkler_pump_flow,
        y: item.discharge,
        name: `Point ${index + 1}`,
      })),
      fill: false,
      borderColor: "#A7C0E4",
      backgroundColor: "#A7C0E4",
      tension: 0.1,
      hidden: activeDataset !== null && activeDataset !== "Pump 1",
      datalabels: {
        display: false,
      },
    },
    {
      label: "Pump 2",
      data: pump2.map((item, index) => ({
        x: item.sprinkler_pump_flow,
        y: item.discharge,
        name: `Point ${index + 1}`,
      })),
      fill: false,
      borderColor: "#3A588D",
      backgroundColor: "#3A588D",
      tension: 0.1,
      hidden: activeDataset !== null && activeDataset !== "Pump 2",
      datalabels: {
        display: false,
      },
    },
    {
      label: "Pump 3",
      data: pump3.map((item, index) => ({
        x: item.sprinkler_pump_flow,
        y: item.discharge,
        name: `Point ${index + 1}`,
      })),
      fill: false,
      borderColor: "#6D81A0",
      backgroundColor: "#6D81A0",
      tension: 0.1,
      hidden: activeDataset !== null && activeDataset !== "Pump 3",
      datalabels: {
        display: false,
      },
    },
    {
      label: "Pump 4",
      data: pump4.map((item, index) => ({
        x: item.sprinkler_pump_flow,
        y: item.discharge,
        name: `Point ${index + 1}`,
      })),
      fill: false,
      borderColor: "#5D84BF",
      backgroundColor: "#5D84BF",
      tension: 0.1,
      hidden: activeDataset !== null && activeDataset !== "Pump 4",
      datalabels: {
        display: false,
      },
    },
    {
      label: "Supply Places",
      data: waterSupply.map((item) => ({
        x: item.demand_flow,
        y: item.demand_pressure,
        name: item.sprinkler_system,
      })),
      fill: false,
      borderColor: ["#A5862A"],
      backgroundColor: ["#A5862A"],
      tension: 0.4,
      showLine: false,
      borderWidth: 1,
      hidden: activeDataset !== null && activeDataset !== "Supply Places",
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value.name,
      },
    },
  ];

  const data = {
    datasets: getDataSets(),
  };

  const get = async () => {
    try {
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPump1(data.pump1.pump_performance);
      setPump2(data.pump2.pump_performance);
      setPump3(data.pump3.pump_performance);
      setPump4(data.pump4.pump_performance);
      setWaterSupply(data.water_supply.place);
      console.log("pump", data.pump1.pump_performance);
    } catch (error) {}
  };

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "flex-start",
        }}
      >
        <Grid container item xs={12}>
          <Line options={options} data={data} />
        </Grid>
      </Grid>
    </div>
  );
}
