import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CreateAssessment from "./CreateAssessment";
import { Grid, Button, Typography } from "@mui/material";
import axios from "../../libs/axios";
import "../Assesment/styles.css";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../screens/store/actions/authActions";
import EditIcon from "@material-ui/icons/Edit";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DeletePopup from "../AssessmentsTable/DeletePopup";
// import axiosseconnd from "axios";

// import PdfComplete from "../Stepper7/PdfComplete";

const columns = [
  { id: "id", label: "Assessment Id", minWidth: 100 },
  { id: "company_name", label: "Company Name", minWidth: 100 },
  {
    id: "contact_name",
    label: "Contact Name",
    minWidth: 100,
  },
  {
    id: "company_contact_email",
    label: "Contact Email",
    minWidth: 100,
  },
  // {
  //   id: "company_contact_number",
  //   label: "Contact Number",
  //   minWidth: 100,
  // },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
  },
  { id: "view", label: "Action", minWidth: 170 },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "1rem 1rem 0 1rem",
  },
  container: {
    maxHeight: 600,
  },
});

export default function AssessmentsTable() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [callingBackend, setCallingBackend] = useState(false);
  const [snackalert, setSnackalert] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  let navigate = useNavigate();

  let dispatch = useDispatch();

  const accessToken = sessionStorage.getItem("accessToken");
  if (!accessToken) {
    navigate("/");
  }
  const onLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  const loadData = async () => {
    try {
      setCallingBackend(true);
      const { data } = await axios.get("/assesment/");
      setRows(data);
    } catch (error) {
      console.error("Error loading data", error);
    } finally {
      setCallingBackend(false);
    }
  };
  const onUpdate = (id) => {
    navigate("/assessment", { state: { id } });
  };

  const onComplete = (id) => {
    navigate("/complete", { state: { id } });
  };

// const downloadPDF = async (url, fileName) => {
//   try {
//     // Fetch the PDF as a Blob
//     const response = await axiosseconnd.get(url, {
//       responseType: "blob", // Important for downloading files
//     });

//     // Create a URL for the blob
//     const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

//     // Create a link element
//     const link = document.createElement("a");
//     link.href = blobUrl;
//     link.setAttribute("download", fileName); // Set the file name

//     // Append to the document and click to trigger download
//     document.body.appendChild(link);
//     link.click();

//     // Clean up and remove the link
//     link.parentNode.removeChild(link);
//   } catch (error) {
//     console.error("Error downloading the file", error);
//   }
//   };
  
  useEffect(() => {
    loadData();
  }, []);

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        justifyContent: "center",
        alignContent: "flex-start",
      }}
    >
      <Grid
        container
        item
        className="mainscreenview"
        sx={{
          height: "100vh",
          position: "absolute",
          zIndex: -1,
        }}
      >
        {" "}
      </Grid>

      <Grid
        container
        item
        xs={10}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          marginTop: "2rem",
          zIndex: 15,
        }}
      >
        <Typography
          sx={{ fontSize: "2rem", color: "#22345B", fontWeight: 700 }}
        >
          Assessments View
        </Typography>
        <Grid
          container
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
          }}
        >
          <CreateAssessment loadData={loadData} />
          <Button
            onClick={() => onLogout()}
            sx={{
              backgroundColor: "#22345B",
              textTransform: "capitalize",
              fontFamily: "serif",
              color: "#ffffff",
              borderRadius: "8px",
              marginLeft: "1rem",
              ":hover": { backgroundColor: "#22345B" },
            }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
      {callingBackend ? (
        <Grid
          container
          item
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "2rem 0",
            zIndex: 15,
            height: "70vh",
          }}
        >
          <CircularProgress size={50} sx={{ color: "#22345B" }} />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "start",
            margin: "2rem 0",
            zIndex: 15,
          }}
        >
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align || "left"}
                        style={{
                          minWidth: column.minWidth,
                          fontSize: "1rem",
                          fontWeight: 700,
                          color: "#22345B",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell key="id">{row?._id}</TableCell>
                        <TableCell key="company_name">
                          {row?.assessment?.company_name.slice(0, 20) +
                            (row?.assessment?.company_name.length > 20
                              ? "..."
                              : "")}
                        </TableCell>
                        <TableCell key="contact_name">
                          {row?.assessment?.company_contact_first_name.slice(
                            0,
                            20
                          ) +
                            (row?.assessment?.company_contact_first_name
                              .length > 20
                              ? "..."
                              : "")}
                        </TableCell>
                        <TableCell key="company_contact_email">
                          {row?.assessment?.company_contact_email}
                        </TableCell>
                        {/* <TableCell key="company_contact_number">
                        {row?.assessment?.company_contact_number}
                      </TableCell> */}
                        <TableCell key="status">
                          {row?.status === "complete" ? (
                            <p style={{ color: "#22345B" }}>Complete</p>
                          ) : (
                            "In Progress"
                          )}
                        </TableCell>
                        <TableCell key="company_contact_last_name">
                          <Button
                            onClick={() => onUpdate(row?._id)}
                            sx={{
                              backgroundColor: "#b19777",
                              textTransform: "capitalize",
                              fontFamily: "serif",
                              borderRadius: "8px",
                              color: "#ffffff",
                              ":hover": { backgroundColor: "#b19777" },
                            }}
                          >
                            <EditIcon />
                          </Button>
                          {row?.status === "complete" ? (
                            <a
                              href={row?.pdflink}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <Button
                                sx={{
                                  marginLeft: "5px",
                                  backgroundColor: "#b19777",
                                  textTransform: "capitalize",
                                  fontFamily: "serif",
                                  borderRadius: "8px",
                                  color: "#ffffff",
                                  ":hover": { backgroundColor: "#b19777" },
                                }}
                              >
                                <GetAppIcon />
                              </Button>
                            </a>
                          ) : (
                            <Button
                              onClick={() => onComplete(row?._id)}
                              disabled
                              sx={{
                                marginLeft: "5px",
                                textTransform: "capitalize",
                                backgroundColor: "#eee",
                                fontFamily: "serif",
                                borderRadius: "8px",
                                color: "#ffffff",
                              }}
                            >
                              <GetAppIcon />
                            </Button>
                          )}
                          {/* <Button
                            onClick={() =>
                              downloadPDF(row?.pdflink, "assesssment")
                            }
                            sx={{
                              marginLeft: "5px",
                              backgroundColor: "#b19777",
                              textTransform: "capitalize",
                              fontFamily: "serif",
                              borderRadius: "8px",
                              color: "#ffffff",
                              ":hover": { backgroundColor: "#b19777" },
                            }}
                          >
                            <GetAppIcon />
                          </Button> */}
                          <DeletePopup
                            id={row?._id}
                            setSnackalert={setSnackalert}
                            loadData={loadData}
                            name={row?.assessment?.company_name}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Snackbar
                open={snackalert}
                autoHideDuration={5000}
                onClose={!snackalert}
              >
                <Alert
                  onClose={!snackalert}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  Assessment deleted successfully!
                </Alert>
              </Snackbar>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 21, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
