import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import axiosSecond from "../../libs/axios";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import loadingGif from "../../assets/img/loader.gif";
import CircularProgress from "@mui/material/CircularProgress";

//REACT_APP_BE_URL=https://f4sjvimixg.execute-api.us-east-1.amazonaws.com/dev/
const PDFViewer = ({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) => {
  const { state } = useLocation();
  const [assessmentPdf, setAssessmentPdf] = useState(null);
  // const [pdfBlob, setPdfBlob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errormg, setErrormg] = useState(false);

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  // const handlePdfFile = async () => {
  //   if (!pdfBlob) {
  //     console.error("No PDF Blob available for upload");
  //     return;
  //   }

  //   try {
  //     // Create a unique filename
  //     const fileName = `document_${new Date().getTime()}.pdf`;

  //     // Create a reference to the file in Firebase Storage
  //     const storageRef = ref(storage, `/files/${fileName}`);

  //     // Upload the Blob
  //     const uploadTask = uploadBytesResumable(storageRef, pdfBlob);

  //     uploadTask.on(
  //       "state_changed",
  //       (snapshot) => {
  //         const percent = Math.round(
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //         );
  //         console.log("Upload progress:", percent + "%"); // Optional: You can use this to show upload progress
  //       },
  //       (error) => {
  //         console.error("Upload error:", error);
  //       },
  //       () => {
  //         // Get download URL
  //         getDownloadURL(uploadTask.snapshot.ref).then((url) => {
  //           submit(url);
  //         });
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Error uploading PDF to Firebase", error);
  //   }
  // };

  const submit = async () => {
    try {
      setCallingBackend(true);

      await axiosSecond.put(`/assesment/pdf-upload/${state.id}`, {
        pdflink: assessmentPdf,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Assessment created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Assessment created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      console.error("Error submitting the URL", error);
    }
  };

  // const get = async () => {
  //   try {
  //     const response = await axios.get(`/pdfdownload/`, {
  //       responseType: "blob",
  //       data: formData,
  //     });
  //     const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  //     const pdfUrl = URL.createObjectURL(pdfBlob);
  //     setAssessmentPdf(pdfUrl);
  //     setPdfBlob(pdfBlob);
  //     setLoading(false);
  //     console.log("Fetched PDF URL", pdfUrl);
  //   } catch (error) {
  //     console.error("Error fetching the PDF", error);
  //     setLoading(false);
  //   }
  // };
  //        `https://place-risk-api.yivaceylon.com/pdfdownload/`,
  //  const getRi = async () => {
  //    try {
  //      const { data } = await axiosSecond.get(`/ri/`);
  //      setSelectRi(data);
  //    } catch (error) {}
  //  };
  const get = async () => {
    try {
     const lastTwoMPractice =
       formData?.management_practice?.m_practice?.slice(-2);
     const filteredFireDetection =
       formData?.fire_detection_protection?.f_d_protection?.filter(
         (item) => item?.id === 6 || item?.id === 9
       );

     const m_practice_reactive = [
       ...lastTwoMPractice,
       ...filteredFireDetection,
     ];

     const m_practice_proactive =
       formData?.management_practice?.m_practice?.filter(
         (item, index) =>
           index < formData?.management_practice?.m_practice?.length - 2
       );

     const priorityarr = formData?.priority?.priorityri?.filter(
       (item) => item?.priority !== "Closed"
     );
     const prioritynotclosed = formData?.priority?.priorityri?.filter(
       (item) => item?.priority !== "Closed"
     );
     const priorityclosed = formData?.priority?.priorityri?.filter(
       (item) => item?.priority === "Closed"
     );
     const prioritydays = formData?.priority?.priorityri?.filter(
       (item) => item?.client_response === "Will Be Completed In 90 Days"
     );
     const hazardarr = formData?.special_hazard?.hazard?.filter(
       (item) => item?.name !== "" || item?.type?.name !== ""
     );

     const { data } = await axiosSecond?.get(`/ri/`);

     const priorityRISet = formData?.priority?.priorityri?.map((item) => {
       if (item?.ri < 50) {
         const datanew = data?.find((i) => i?.rinum === item?.ri);
         return {
           ...item,
           theRisk: datanew?.theRisk,
           detail: datanew?.detail,
         };
       } else {
         const datanew = formData?.new_ri?.ri?.find((i) => i?.ri === item?.ri);
         return {
           ...item,
           theRisk: datanew?.theRisk,
           detail: datanew?.details,
         };
       }
     });
     const priorityRI = priorityRISet?.map((item) => {
       if (item?.ri === 39) {
         const datanew = formData?.special_hazard?.hazard?.find(
           (i) => i?.rioption?.title === item?.title
         );
         return {
           ...item,
           theRisk: datanew?.rioption?.theRisk,
           detail: datanew?.rioption?.details,
         };
       } else {
         return {
           ...item,
         };
       }
     });
     const riskImproments = priorityRI?.map((item) => {
       return { improvment: { ...item } };
     });
     console?.log("riskImproments", riskImproments);
     const newbasic_details = formData?.basic_details?.section1
       ?.filter(
         (item) =>
           item?.name !== "Other" ||
           (item?.name === "Other" && item?.value !== 0)
       )
       ?.map((item) => {
         return {
           ...item,
           value: item?.value?.toLocaleString(),
         };
       });
     const o_overview = formData?.construction_page?.construction_type?.map(
       (item) => {
         const datanew = formData?.occupancy_page?.occupancy_items?.find(
           (i) => i?.building === item?.name
         );
         return {
           ...item,
           occupancy: datanew?.occupancy,
           year: datanew?.year,
           condition: datanew?.condition,
           occ_comment: datanew?.comment,
         };
       }
     );
     const watersupply = formData?.water_supply?.place?.map((item) => {
       const datanew = formData?.sprinkler_system_reliability?.sprinkler?.find(
         (i) => i?.id === item?.id
       );
       return {
         ...item,
         reliability: datanew?.reliability,
       };
     });
     const hydrantsystem = formData?.hydrant_system?.hydrant?.map((item) => {
       const datanew = formData?.hydrant_system_reliability?.hydrant?.find(
         (i) => i?.id === item?.id
       );
       return {
         ...item,
         reliability: datanew?.reliability,
       };
     });
     const mfl_property_damage =
       formData?.mfl_calculation?.total_of_mflfire?.toLocaleString() || 0;
     const mfl_bi =
       formData?.mfl_calculation?.total_of_mfl_bi?.toLocaleString() || 0;
     const mfl_total =
       formData?.mfl_calculation?.total_of_loss?.toLocaleString() || 0;
     const mfl_other = formData?.liability?.total_of_mfl?.toLocaleString() || 0;
     const nle_property_damage =
       formData?.nle_calculation?.total_of_nlefire?.toLocaleString() || 0;
     const nle_bi =
       formData?.nle_calculation?.total_of_nle_bi?.toLocaleString() || 0;
     const nle_total =
       formData?.nle_calculation?.total_of_loss?.toLocaleString() || 0;
     const nle_other = formData?.liability?.total_of_nle?.toLocaleString() || 0;
     const wle_property_damage =
       formData?.wle_calculation?.total_of_wlefire?.toLocaleString() || 0;
     const wle_bi =
       formData?.wle_calculation?.total_of_wle_bi?.toLocaleString() || 0;
     const wle_total =
       formData?.wle_calculation?.total_of_loss?.toLocaleString() || 0;
     const wle_other =
       (formData?.wleliability?.wle_edit
         ? formData?.wleliability?.total_of_wle?.toLocaleString()
         : formData?.liability?.total_of_wle?.toLocaleString()) || 0;
     const basic_details_s1_total =
       formData?.basic_details?.section1_total?.toLocaleString() || 0;
     const basic_details_s2_total =
       formData?.basic_details?.section2_total?.toLocaleString() || 0;
     const basic_details_s1_s2_total =
       formData?.basic_details?.total_s1_s2?.toLocaleString() || 0;
     const basic_details_bi =
       formData?.basic_details?.business_interuption?.toLocaleString() || 0;
     const basic_details_bi_o_ip =
       formData?.basic_details?.bi_over_ip?.toLocaleString() || 0;

      const json = {
        ...formData,
        priorityarr,
        priorityclosed,
        priorityRI,
        o_overview,
        watersupply,
        hydrantsystem,
        hazardarr,
        prioritynotclosed,
        m_practice_reactive,
        m_practice_proactive,
        newbasic_details,
        prioritydays,
        mfl_property_damage,
        mfl_bi,
        mfl_total,
        mfl_other,
        nle_property_damage,
        nle_bi,
        nle_total,
        nle_other,
        wle_property_damage,
        wle_bi,
        wle_total,
        wle_other,
        basic_details_s1_total,
        basic_details_s2_total,
        basic_details_s1_s2_total,
        basic_details_bi,
        basic_details_bi_o_ip,
        riskImproment: { ...riskImproments },
      };

      console.log("hazardarr", json);

      const response = await axios.post(
        `https://place-risk-api.yivaceylon.com/pdfdownload/`,
        {
        data: json,
      });
      if (response?.data?.message === "success") {
        const { data } = await axios.get(
          `https://place-risk-api.yivaceylon.com/pdfdownload/upload`
        );
        console.log("response", data);
        setAssessmentPdf(data?.pdf_url);
        setLoading(false);
      } else {
        setLoading(false);
        setErrormg(true);
      }
    } catch (error) {
      console.error("Error occurred while downloading PDF:", error);
      // Handle network errors or other exceptions
    }
  };

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="formName">PDF Generation</p>
        <Grid
          container
          xs={10}
          spacing={1}
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          {errormg && (
            <Typography>
              Server error! Check back in a few minutes and try again.
            </Typography>
          )}
          {loading ? (
            <>
              <Grid
                container
                xs={10}
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <img src={loadingGif} alt="Loading..." width="400px" />
                <Typography
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    bottom: "100px",
                    width: "100%",
                  }}
                >
                  Please wait while PRS AREA generates your report…
                </Typography>
              </Grid>
            </>
          ) : (
            assessmentPdf && (
              <iframe
                src={assessmentPdf}
                width="100%"
                height="700px"
                title="Assessment Report"
              />
            )
          )}
          <Grid
            container
            item
            xs={12}
            sx={{
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              onClick={() => handleClick()}
              disabled={previouscallingBackend}
            >
              {previouscallingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Previous"
              )}
            </Button>
            <Button
              variant="contained"
              onClick={() => submit()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={!assessmentPdf || callingBackend}
            >
              {callingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Complete"
              )}
            </Button>
          </Grid>
          {alert.showAlert && (
            <Grid
              container
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Grid item md={8}>
                <Alert
                  severity={alert.severity}
                  onClose={() => setAlert({ ...alert, showAlert: false })}
                >
                  {alert.message}
                </Alert>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default PDFViewer;
