//super admin
export const routes = [
  {
    name: "ADMIN",
    children: [
      {
        name: "Assessment",
        path: "/assessment",
      },
    ],
  },
];
//super admin
export const routes2 = [
  {
    name: "ADMIN",
    children: [
      {
        name: "Assessment",
        path: "/assessment",
      },
    ],
  },
];