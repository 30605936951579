import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function MFLPage({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const section1 = [
    {
      id: 0,
      name: "Building",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 1,
      name: "Contents",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 2,
      name: "Stocks",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 3,
      name: "Other",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 4,
      name: "Other",
      fire_area: 0,
      damages: 0,
    },
    {
      id: 5,
      name: "Other",
      fire_area: 0,
      damages: 0,
    },
  ];
  const section2 = [
    {
      id: 0,
      name: "Phase 1",
      of_BI: 0,
      months: 0,
      affected: 0,
    },
    {
      id: 1,
      name: "Phase 2",
      of_BI: 0,
      months: 0,
      affected: 0,
    },
  ];

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();
  const [arr, setArr] = useState(
    formData?.mfl_calculation?.section1?.length ? [...formData?.mfl_calculation?.section1] : section1
  );
  const [arrsec2, setArrsec2] = useState(formData?.mfl_calculation?.section2?.length ? [...formData?.mfl_calculation?.section2] : section2);
  const mflarr = formData?.basic_details?.section1.length ? [...formData?.basic_details?.section1] : [];
  const bimonth = formData?.basic_details?.bi_over_ip ? formData?.basic_details?.bi_over_ip : 0;
  const mflsublimits =
    formData?.liability?.total_of_mfl ? formData?.liability?.total_of_mfl : 0;
  const [quillbar, setQuillbar] = useState(formData?.mfl_calculation?.comment ? formData?.mfl_calculation?.comment : "");
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  const changeFormData = (value, name, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };

  const changeFormDataSec2 = (value, name, id) => {
    const objIndex = arrsec2?.findIndex((obj) => obj?.id === id);
    arrsec2[objIndex][name] = value;

    setArrsec2([...arrsec2]);
  };

 const submit = async () => {
    const summflfire = arr?.map((item, idx) => {

      return (
        (Number(mflarr[idx]?.value) *
          Number(item?.fire_area) *
          Number(item?.damages)) /
          10000
      );
    });
   console.log("summflfire", summflfire);
    const newsummflfire = summflfire?.reduce((n, m) => n + m, 0);

    const totalmflbi = arrsec2?.reduce(
      (n, { of_BI, affected, months }) =>
        n +
        (Number(bimonth) * Number(of_BI) * Number(affected) * Number(months)) /
          10000,
      0
    );
      try {
        setCallingBackend(true);
        await axios.put(`/assesment/mfl-calculation/${state.id}`, {
          section1: arr,
          section2: arrsec2,
          total_of_mflfire: Math.floor(newsummflfire),
          total_of_loss: Math.floor(newsummflfire + totalmflbi),
          total_of_mfl_bi: Math.floor(totalmflbi),
          mfl_estimate: Math.floor(newsummflfire + totalmflbi + mflsublimits),
          mfl_estimate_precentage: Math.floor(((newsummflfire + totalmflbi + mflsublimits)/formData?.basic_details?.total_s1_s2)*100),
          comment: quillbar,
        });
        setAlert({
          showAlert: true,
          severity: "success",
          message: "MFL calculation page created successfully!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "MFL calculation page created successfully!",
            }),
          handleNextPage(),
          5000
        );
      } catch (error) {
        if (error.response.data === "MFL calculation page already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "MFL calculation page already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "MFL calculation page already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "MFL calculation page already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "MFL calculation page already exists!",
              }),
            5000
          );
        }
      } 
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        initialValues={section1}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">
                  Maximum Foreseeable Loss (MFL) Calculation
                </p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={2}>
                      <p className="inputTextH">Property Damage (Section 1)</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">% Fire Area</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">% Damages</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={7}></Grid>
                  </Grid>
                  {arr.map((item, i) => {
                    return (
                      <Grid
                        container
                        xs={12}
                        key={item.id}
                        sx={{
                          justifyContent: 'flex-start',
                          alignContent: "center",
                        }}
                        spacing={1}
                      >
                        <Grid className="inputTextGrid" item xs={2}>
                          <InputFeild
                            name="name"
                            value={item?.name}
                            onChange={(e) =>
                              changeFormData(e?.target?.value, "name", item?.id)
                            }
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>

                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="fire_area"
                              value={item?.fire_area}
                              type="number"
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "fire_area",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="damages"
                              type="number"
                              value={item?.damages}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "damages",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={7} spacing={1}></Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <Formik
        onSubmit={submit}
        initialValues={section2}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={2}>
                      <p className="inputTextH">
                        Business Interruption (Section 2)
                      </p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">% Of BI</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">Months</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">% Affected</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={5.5}></Grid>
                  </Grid>
                  {arrsec2.map((item, i) => {
                    return (
                      <Grid
                        container
                        xs={12}
                        key={item.id}
                        sx={{
                          justifyContent: "space-between",
                          alignContent: "center",
                        }}
                        spacing={1}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={1}
                          style={{
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                          }}
                        >
                          <Grid className="inputTextGrid" item xs={2}>
                            <InputFeild
                              name="name"
                              value={item?.name}
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "name",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                          <Grid container item xs={1.5} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="of_BI"
                                type="number"
                                value={item?.of_BI}
                                onChange={(e) =>
                                  changeFormDataSec2(
                                    e?.target?.value,
                                    "of_BI",
                                    item?.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={1.5} spacing={1}>
                            <Grid item xs={12}>
                              <TextField
                                name="months"
                                value={item?.months}
                                type="number"
                                onChange={(e) => {
                                  changeFormDataSec2(
                                    e?.target?.value,
                                    "months",
                                    item?.id
                                  );
                                }}
                                InputProps={{
                                  inputProps: { step: "0.01", min: "0" },
                                }}
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="dense"
                                style={{ backgroundColor: "#FFFFFF" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={1.5} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="affected"
                                value={item?.affected}
                                type="number"
                                onChange={(e) =>
                                  changeFormDataSec2(
                                    e?.target?.value,
                                    "affected",
                                    item?.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={5.5} spacing={1}></Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    style={{ paddingTop: "2rem" }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextH">MFL Scenario</p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                      {/* <InputFeild
                        name="comment"
                        component={TextField}
                        value={comment}
                        onChange={(e) => setComment(e?.target?.value)}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={10}
                      />{" "} */}
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar}
                        style={{ marginBottom: "25px", height: "300px" }}
                        onChange={setQuillbar}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(formData?.mfl_calculation, null, 2)}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={!isValid || callingBackend}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
