import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/TextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import ImageUpload from "../../components/common/imageUpload/ImageUpload";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";


export default function SitePhotos({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [image7, setImage7] = useState("");
  const [image8, setImage8] = useState("");
  const [image9, setImage9] = useState("");
  const [image10, setImage10] = useState("");
  const [image11, setImage11] = useState("");
  const [image12, setImage12] = useState("");

  const [initialValues, setInitialValues] = useState({
    image1: "",
    image1name: "",
    image2: "",
    image2name: "",
    image3: "",
    image3name: "",
    image4: "",
    image4name: "",
    image5: "",
    image5name: "",
    image6: "",
    image6name: "",
    image7: "",
    image7name: "",
    image8: "",
    image8name: "",
    image9: "",
    image9name: "",
    image10: "",
    image10name: "",
    image11: "",
    image11name: "",
    image12: "",
    image12name: "",
  });


  const submit = async (e, { resetForm }) => {
      try {
        setCallingBackend(true);
        await axios.put(`/assesment/site_photos/${state.id}`, {
          image1: image1,
          image1name: e.image1name,
          image2: image2,
          image2name: e.image2name,
          image3: image3,
          image3name: e.image3name,
          image4: image4,
          image4name: e.image4name,
          image5: image5,
          image5name: e.image5name,
          image6: image6,
          image6name: e.image6name,
          image7: image7,
          image7name: e.image7name,
          image8: image8,
          image8name: e.image8name,
          image9: image9,
          image9name: e.image9name,
          image10: image10,
          image10name: e.image10name,
          image11: image11,
          image11name: e.image11name,
          image12: image12,
          image12name: e.image12name,
        });
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Site photos saved successfully!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Site photos saved successfully!",
            }),
          handleNextPage(),
          5000
        );
      } catch (error) {
        if (error.response.data === "Site photos already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Site photos already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Site photos already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Site photos already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Site photos already exists!",
              }),
            5000
          );
        }
      }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  useEffect(() => {
    setInitialValues({
      image1: formData?.site_photos?.image1,
      image1name: formData?.site_photos?.image1name,
      image2: formData?.site_photos?.image2,
      image2name: formData?.site_photos?.image2name,
      image3: formData?.site_photos?.image3,
      image3name: formData?.site_photos?.image3name,
      image4: formData?.site_photos?.image4,
      image4name: formData?.site_photos?.image4name,
      image5: formData?.site_photos?.image5,
      image5name: formData?.site_photos?.image5name,
      image6: formData?.site_photos?.image6,
      image6name: formData?.site_photos?.image6name,
      image7: formData?.site_photos?.image7,
      image7name: formData?.site_photos?.image7name,
      image8: formData?.site_photos?.image8,
      image8name: formData?.site_photos?.image8name,
      image9: formData?.site_photos?.image9,
      image9name: formData?.site_photos?.image9name,
      image10: formData?.site_photos?.image10,
      image10name: formData?.site_photos?.image10name,
      image11: formData?.site_photos?.image11,
      image11name: formData?.site_photos?.image11name,
      image12: formData?.site_photos?.image12,
      image12name: formData?.site_photos?.image12name,
    });
    setImage1(formData?.site_photos?.image1);
    setImage2(formData?.site_photos?.image2);
    setImage3(formData?.site_photos?.image3);
    setImage4(formData?.site_photos?.image4);
    setImage5(formData?.site_photos?.image5);
    setImage6(formData?.site_photos?.image6);
    setImage7(formData?.site_photos?.image7);
    setImage8(formData?.site_photos?.image8);
    setImage9(formData?.site_photos?.image9);
    setImage10(formData?.site_photos?.image10);
    setImage11(formData?.site_photos?.image11);
    setImage12(formData?.site_photos?.image12);
  }, [formData]);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Site Photographs</p>
                <Grid container xs={10} spacing={1}>
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 1 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image1name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage1}
                        image={image1}
                        name="image1"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 2 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image2name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage2}
                        image={image2}
                        name="image2"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 3 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image3name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage3}
                        image={image3}
                        name="image3"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 4 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image4name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage4}
                        image={image4}
                        name="image4"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 5 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image5name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage5}
                        image={image5}
                        name="image5"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 6 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image6name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage6}
                        image={image6}
                        name="image6"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 7 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image7name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage7}
                        image={image7}
                        name="image7"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 8 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image8name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage8}
                        image={image8}
                        name="image8"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 9 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image9name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage9}
                        image={image9}
                        name="image9"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 10 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image10name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage10}
                        image={image10}
                        name="image10"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 11 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image11name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage11}
                        image={image11}
                        name="image11"
                      />
                    </Grid>
                  </Grid>{" "}
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={1}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                    }}
                  >
                    <Grid item xs={12}>
                      <p className="inputTextP">Image 12 name:</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <InputFeild
                        name="image12name"
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        setImage={setImage12}
                        image={image12}
                        name="image12"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!image1 || callingBackend}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
