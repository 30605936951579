import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
// import { validationSchema } from "../../utils/validations/essentialsService";
// import Autocomplete from "@mui/material/Autocomplete";

export default function Pump1({
  getPage,
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
  skipcallingBackend,
  setSkipCallingBackend,
  handleNextPumpPage,
}) {
   const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
     width: "100%", // Ensures the DatePicker takes full width
     "& .MuiInputBase-root": {
       // Apply styles to the input root
       backgroundColor: "#FFFFFF",
       paddingTop: 0,
       marginTop: "-8px",
       transition: theme.transitions.create(["border-color", "box-shadow"]),
       width: "100%", // Ensures the input base takes full width
     },
     "& .MuiInputBase-input": {
       // Apply styles to the input text
       fontSize: 16,
       width: "100%", // Ensures the input text takes full width
       padding: "9px",
     },
   }));
  const { state } = useLocation();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const inputArr = [
    {
      id: 0,
      sprinkler_pump_flow: "",
      discharge: "",
    },
  ];

  const [arr, setArr] = useState(formData?.pump1?.pump_performance?.length ? [...formData?.pump1?.pump_performance] : inputArr);
  const [number, setNumber] = useState(
    formData?.pump1?.pump_performance?.length ? formData?.pump1?.pump_performance?.length :1
  );
  const [testdate, setTestdate] = useState(formData?.pump1?.test_date ? formData?.pump1?.test_date : "");
  const [description, setDescription] = useState(formData?.pump1?.description ? formData?.pump1?.description : "");
  const [watersource, setWatersource] = useState(formData?.pump1?.water_source ? formData?.pump1?.water_source : "");

  const addInput = () => {
    setNumber(number + 1);
    setArr((s) => {
      return [
        ...s,
        {
          id: number,
          sprinkler_pump_flow: "",
          discharge: "",
        },
      ];
    });
  };
  const changeFormData = (value, name, id) => {
    const objIndex = arr.findIndex((obj) => obj.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };

  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/pump1/${state.id}`, {
        pump_performance: arr,
        test_date: dayjs(testdate).format("YYYY-MM-DD"),
        description: description,
        water_source: watersource,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Pump 1 performance created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Pump 1 performance created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Pump 1 performance already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Pump 1 performance already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Pump 1 performance already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Pump 1 performance already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Pump 1 performance already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  const handleSkip = () => {
        setSkipCallingBackend(true);
    if (
      formData?.assessment?.water_supply_exclude &&
      formData?.assessment?.hysrant_water_supply_exclude
    ) {
      handleNextPumpPage(formData?.form_number + 5);
    } else {
       handleNextPumpPage(formData?.form_number + 4);
    }
  };

  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Pump 1 Water Supply</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Test Date</p>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <StyledDatePicker
                            value={testdate ? dayjs(testdate) : null}
                            onChange={(newValue) => {
                              const formattedDate = newValue
                                ? newValue.format("YYYY-MM-DD")
                                : null;
                              setTestdate(formattedDate);
                            }}
                            format="YYYY-MM-DD"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                      <p className="inputTextP">Description</p>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <InputFeild
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description || ""}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        multiline
                        rows={3}
                      />{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <p className="inputTextP">Water Source</p>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <InputFeild
                        name="water_source"
                        onChange={(e) => setWatersource(e.target.value)}
                        value={watersource || ""}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "3rem" }}>
                      <p className="formName">Pump Performance</p>
                    </Grid>
                    <Grid container item xs={6} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">
                          Sprinkler Pump Flow (L/min)
                        </p>{" "}
                      </Grid>
                    </Grid>
                    {/* <Grid container item xs={4} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Suction (kPa)</p>{" "}
                      </Grid>
                    </Grid> */}
                    <Grid container item xs={6} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Discharge (kPa)</p>{" "}
                      </Grid>
                    </Grid>
                    {arr.map((item, i) => {
                      return (
                        <>
                          <Grid container item xs={6} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="sprinkler_pump_flow"
                                value={item.sprinkler_pump_flow}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "sprinkler_pump_flow",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                              />{" "}
                            </Grid>
                          </Grid>
                          {/* <Grid container item xs={4} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="suction"
                                value={item.suction}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "suction",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                              />{" "}
                            </Grid>
                          </Grid> */}
                          <Grid container item xs={6} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="discharge"
                                value={item.discharge}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "discharge",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                              />{" "}
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                    <Button
                      onClick={addInput}
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        marginTop: "1.5rem",
                        marginLeft: "0.5rem",
                      }}
                      disabled={
                        number >= 30 ||
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) => value === ""
                            )
                        )
                      }
                    >
                      Add Field
                    </Button>
                  </Grid>
                  <Grid item xs={6} spacing={1}>
                    <pre>{JSON.stringify(formData?.pump1, null, 2)}</pre>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#22345B",
                          textTransform: "capitalize",
                          fontFamily: "serif",
                          borderRadius: "8px",
                          marginRight: "1rem",
                          ":hover": { backgroundColor: "#22345B" },
                        }}
                        disabled={skipcallingBackend}
                        onClick={() => {
                          handleSkip();
                        }}
                      >
                        {skipcallingBackend ? (
                          <CircularProgress
                            size={25}
                            sx={{ color: "#22345B", margin: "0 5px" }}
                          />
                        ) : (
                          "Skip"
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#22345B",
                          textTransform: "capitalize",
                          fontFamily: "serif",
                          borderRadius: "8px",
                          ":hover": { backgroundColor: "#22345B" },
                        }}
                        onClick={() => submit()}
                        disabled={
                          !arr.every(
                            (item) =>
                              !Object.entries(item).some(
                                ([key, value]) => value === ""
                              )
                          ) ||
                          !testdate ||
                          !description ||
                          !watersource ||
                          callingBackend
                        }
                      >
                        {callingBackend ? (
                          <CircularProgress
                            size={25}
                            sx={{ color: "#22345B", margin: "0 5px" }}
                          />
                        ) : (
                          "Next"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
