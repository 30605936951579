import React, { useState, useEffect, useCallback } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import "../Assesment/assesment.css";
import { Button, Typography, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputFeild from "../../components/common/NewTextFeild";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const priorityType = ["Urgent", "Advisory", "Closed"];
const clientResponseType = [
  "Long Term Commitment To Complete",
  "Too Onerous To Complete",
  "Will Be Completed In 90 Days",
];

export default function NaturalHazardsRecommendationRI({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const priorityarr = [
    {
      rinum: "",
      title: "",
      priority: "",
      client_response: "",
      client_comment: "",
    },
  ];

  const { state } = useLocation();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [riPriority, setRiPriority] = useState([]);
  const [selectRi, setSelectRi] = useState([]);
  const [addarr, setAddArr] = useState([]);
  const [number, setNumber] = useState(1);

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["clean"], // remove formatting button
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  const [checkNHR, setCheckSH] = useState(
    formData?.natural_hazards_recommendation_priority?.nhr_check ? true : false
  );

  const handleNaturalHazardsRecommendation = (event) => {
    setCheckSH(event.target.checked);
  };

  const handleChange = (arr, setArr, value, name, id) => {
    const objIndex = arr.findIndex((obj, index) => index === id);
    arr[objIndex][name] = value;
    setArr([...arr]);
  };

  const addInput = () => {
    setNumber(riPriority.length + 1);
    setAddArr((prevArr) => [
      ...prevArr,
      {
        id: number,
        rinum: 90 + number,
        title: "",
        details: "",
        theRisk: "",
        priority: "",
        clientResponse: "",
        clientComment: "",
      },
    ]);
  };
  const deleteFieldRI = (id) => {
    setRiPriority((prevArr) => prevArr.filter((item, index) => index !== id));
  };
  const deleteField = (id) => {
    setAddArr((prevArr) => prevArr.filter((item, index) => index !== id));
  };
  const submit = async (e) => {
    const combinedArray = [...riPriority, ...addarr];
    try {
      setCallingBackend(true);
      await axios.put(
        `/assesment/natural_hazards_recommendation_priority/${state.id}`,
        {
          priorityri_nhr: combinedArray,
          nhr_check: checkNHR,
        }
      );
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Natural Hazards Recommendation RI page created successfully!",
      });
      setTimeout(() => setAlert({ showAlert: false }), 5000);
      handleNextPage();
    } catch (error) {
      let message = "Something went wrong!";
      if (error.response) {
        message =
          error.response.data ===
          "Natural Hazards Recommendation RI page already exists"
            ? "Natural Hazards Recommendation RI page already exists!"
            : error.response.status === 401
            ? "Unauthorized Access!"
            : "Server Error!";
      }
      setAlert({
        showAlert: true,
        severity: "error",
        message,
      });
      setTimeout(() => setAlert({ showAlert: false }), 5000);
    } finally {
      setCallingBackend(false);
    }
  };

  const getRi = useCallback(async () => {
    try {
      const { data } = await axios.get(`/ri/`);
      const filteredRiPriorityexposure =
        formData?.natural_hazards_exposure?.n_h_exposure.filter(
          (item) => item?.type?.value !== 1
        ) || [];
      const newdataexposure = formData?.natural_hazards_exposure_priority
        ?.priorityri_nhe?.length
        ? formData.natural_hazards_exposure_priority.priorityri_nhe
        : filteredRiPriorityexposure.map((item) => {
            const foundRi = data.find((mapRi) => mapRi.rinum === item.ri) || {};
            return {
              ...item,
              title: foundRi?.title || "",
              ri: foundRi?.rinum || "",
              theRisk: foundRi?.theRisk || "",
              detail: foundRi?.detail || "",
            };
          });
      const filteredRiPriority =
        formData?.natural_hazards_recommendation?.n_h_recommendation.filter(
          (item) => item?.type?.value !== 1
        ) || [];
      const newdata = formData?.natural_hazards_recommendation_priority
        ?.priorityri_nhr?.length
        ? formData.natural_hazards_recommendation_priority.priorityri_nhr
        : filteredRiPriority.map((item) => {
            const foundRi = data.find((mapRi) => mapRi.rinum === item.ri) || {};
            return {
              ...item,
              title: foundRi?.title || "",
              ri: foundRi?.rinum || "",
              theRisk: foundRi?.theRisk || "",
              detail: foundRi?.detail || "",
            };
          });
      setRiPriority([...newdata, ...newdataexposure]);
      setSelectRi(data);
    } catch (error) {
      console.error("Error fetching RI data:", error);
    }
  }, []);

  useEffect(() => {
    getRi();
  }, [getRi]);

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik initialValues={priorityarr} onSubmit={submit} enableReinitialize>
        {() => (
          <Form>
            <Grid
              container
              xs={12}
              sx={{
                padding: "1rem",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="formName">
                {" "}
                PRS AREA - Natural Hazards Risk Improvements
              </p>
              <Grid container xs={10} spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        checked={checkNHR}
                        onChange={(e) => {
                          handleNaturalHazardsRecommendation(e);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Natural Hazards Recommendation RI data disable"
                    labelPlacement="start"
                  />
                </Grid>
                {riPriority.map((item, index) => (
                  <Grid
                    key={index}
                    className="inputTextGrid"
                    container
                    item
                    xs={12}
                  >
                    <Autocomplete
                      hiddenLabel
                      id="ri"
                      name="ri"
                      size="small"
                      sx={{ marginBottom: "5px", width: "100%" }}
                      value={
                        selectRi.find((items) => items.rinum === item.ri) ||
                        null
                      }
                      options={selectRi}
                      onChange={(event, newValue) => {
                        handleChange(
                          riPriority,
                          setRiPriority,
                          newValue?.rinum || "",
                          "ri",
                          index
                        );
                        handleChange(
                          riPriority,
                          setRiPriority,
                          newValue?.title || "",
                          "title",
                          index
                        );
                        handleChange(
                          riPriority,
                          setRiPriority,
                          newValue?.detail || "",
                          "detail",
                          index
                        );
                        handleChange(
                          riPriority,
                          setRiPriority,
                          newValue?.theRisk || "",
                          "theRisk",
                          index
                        );
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    <Grid
                      style={{
                        padding: "1rem 2rem",
                        border: "solid 1px #eee",
                        borderRadius: "5px",
                        margin: "1rem",
                        width: "100%",
                      }}
                      item
                      xs={12}
                    >
                      <Typography>
                        Enter Information for Risk Improvements
                      </Typography>
                      <p className="font" style={{ paddingTop: "2rem" }}>
                        Title
                      </p>
                      <InputFeild
                        name="rioption title"
                        value={item?.title}
                        onChange={(e) =>
                          handleChange(
                            riPriority,
                            setRiPriority,
                            e.target.value,
                            "title",
                            index
                          )
                        }
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />
                      <p className="font" style={{ paddingTop: "2rem" }}>
                        Details
                      </p>
                      <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                        <ReactQuill
                          modules={module}
                          theme="snow"
                          value={item?.detail}
                          style={{ marginBottom: "25px", height: "200px" }}
                          onChange={(value) =>
                            handleChange(
                              riPriority,
                              setRiPriority,
                              value,
                              "detail",
                              index
                            )
                          }
                        />
                      </Grid>
                      <p className="font" style={{ paddingTop: "1rem" }}>
                        Risk
                      </p>
                      <Grid item xs={12} style={{ marginBottom: "60px" }}>
                        <ReactQuill
                          modules={module}
                          theme="snow"
                          value={item?.theRisk}
                          style={{ marginBottom: "25px", height: "200px" }}
                          onChange={(value) =>
                            handleChange(
                              riPriority,
                              setRiPriority,
                              value,
                              "theRisk",
                              index
                            )
                          }
                        />
                      </Grid>
                      <p className="font" style={{ paddingTop: "1rem" }}>
                        Priority
                      </p>
                      <Grid item xs={12}>
                        <Autocomplete
                          hiddenLabel
                          id="priority"
                          name="priority"
                          size="small"
                          value={item.priority}
                          options={priorityType}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) =>
                            handleChange(
                              riPriority,
                              setRiPriority,
                              newValue,
                              "priority",
                              index
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Grid>
                      <p className="font" style={{ paddingTop: "1rem" }}>
                        Client Response
                      </p>
                      <Grid item xs={12}>
                        <Autocomplete
                          hiddenLabel
                          id="client_response"
                          name="client_response"
                          size="small"
                          value={item.client_response}
                          options={clientResponseType}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) =>
                            handleChange(
                              riPriority,
                              setRiPriority,
                              newValue,
                              "client_response",
                              index
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Grid>
                      <p className="font" style={{ paddingTop: "1rem" }}>
                        Client Comment
                      </p>
                      <Grid item xs={12} style={{ marginBottom: "60px" }}>
                        <ReactQuill
                          modules={module}
                          theme="snow"
                          value={item?.client_comment}
                          style={{ marginBottom: "25px", height: "200px" }}
                          onChange={(e) =>
                            handleChange(
                              riPriority,
                              setRiPriority,
                              e,
                              "client_comment",
                              index
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          justifyContent: "flex-end",
                          marginTop: "1rem",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => deleteFieldRI(index)}
                          color="error"
                          sx={{
                            textTransform: "capitalize",
                            marginTop: "1.5rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                {addarr.map((addItem, index) => (
                  <Grid key={index} container item xs={12}>
                    <Autocomplete
                      hiddenLabel
                      id="ri"
                      name="ri"
                      size="small"
                      sx={{ marginBottom: "5px", width: "100%" }}
                      value={
                        selectRi.find((items) => items.rinum === addItem.ri) ||
                        null
                      }
                      options={selectRi}
                      onChange={(event, newValue) => {
                        handleChange(
                          addarr,
                          setAddArr,
                          newValue?.rinum || "",
                          "ri",
                          index
                        );
                        handleChange(
                          addarr,
                          setAddArr,
                          newValue?.title || "",
                          "title",
                          index
                        );
                        handleChange(
                          addarr,
                          setAddArr,
                          newValue?.detail || "",
                          "detail",
                          index
                        );
                        handleChange(
                          addarr,
                          setAddArr,
                          newValue?.theRisk || "",
                          "theRisk",
                          index
                        );
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    <Grid
                      style={{
                        padding: "1rem 2rem",
                        border: "solid 1px #eee",
                        borderRadius: "5px",
                        margin: "1rem",
                        width: "100%",
                      }}
                      item
                      xs={12}
                    >
                      <Typography>
                        Enter Information for Risk Improvements
                      </Typography>
                      <p className="font" style={{ paddingTop: "2rem" }}>
                        Title
                      </p>
                      <InputFeild
                        name="rioption title"
                        value={addItem?.title}
                        onChange={(e) =>
                          handleChange(
                            addarr,
                            setAddArr,
                            e.target.value,
                            "title",
                            index
                          )
                        }
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />
                      <p className="font" style={{ paddingTop: "2rem" }}>
                        Details
                      </p>
                      <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                        <ReactQuill
                          modules={module}
                          theme="snow"
                          value={addItem?.detail}
                          style={{ marginBottom: "25px", height: "200px" }}
                          onChange={(value) =>
                            handleChange(
                              addarr,
                              setAddArr,
                              value,
                              "detail",
                              index
                            )
                          }
                        />
                      </Grid>
                      <p className="font" style={{ paddingTop: "1rem" }}>
                        Risk
                      </p>
                      <Grid item xs={12} style={{ marginBottom: "60px" }}>
                        <ReactQuill
                          modules={module}
                          theme="snow"
                          value={addItem?.theRisk}
                          style={{ marginBottom: "25px", height: "200px" }}
                          onChange={(value) =>
                            handleChange(
                              addarr,
                              setAddArr,
                              value,
                              "theRisk",
                              index
                            )
                          }
                        />
                      </Grid>
                      <p className="font" style={{ paddingTop: "1rem" }}>
                        Priority
                      </p>
                      <Grid item xs={12}>
                        <Autocomplete
                          hiddenLabel
                          id="priority"
                          name="priority"
                          size="small"
                          value={addItem.priority}
                          options={priorityType}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) =>
                            handleChange(
                              addarr,
                              setAddArr,
                              newValue,
                              "priority",
                              index
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Grid>
                      <p className="font" style={{ paddingTop: "1rem" }}>
                        Client Response
                      </p>
                      <Grid item xs={12}>
                        <Autocomplete
                          hiddenLabel
                          id="client_response"
                          name="client_response"
                          size="small"
                          value={addItem.client_response}
                          options={clientResponseType}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) =>
                            handleChange(
                              addarr,
                              setAddArr,
                              newValue,
                              "client_response",
                              index
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Grid>
                      <p className="font" style={{ paddingTop: "1rem" }}>
                        Client Comment
                      </p>
                      <Grid item xs={12} style={{ marginBottom: "60px" }}>
                        <ReactQuill
                          modules={module}
                          theme="snow"
                          value={addItem?.client_comment}
                          style={{ marginBottom: "25px", height: "200px" }}
                          onChange={(e) =>
                            handleChange(
                              addarr,
                              setAddArr,
                              e,
                              "client_comment",
                              index
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          justifyContent: "flex-end",
                          marginTop: "1rem",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => deleteField(index)}
                          color="error"
                          sx={{
                            textTransform: "capitalize",
                            marginTop: "1.5rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                container
                xs={10}
                style={{
                  alignContent: "center",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={addInput}
                  sx={{
                    textTransform: "capitalize",
                    marginTop: "1.5rem",
                    marginLeft: "0.5rem",
                  }}
                  disabled={
                    !addarr.every(
                      (item) =>
                        !Object.entries(item).some(
                          ([key, value]) =>
                            item.title === "" ||
                            item.title === undefined ||
                            item.title === null
                        )
                    )
                  }
                >
                  Add Field
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={10}
                style={{
                  alignContent: "center",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleClick}
                  sx={{
                    backgroundColor: "#22345B",
                    textTransform: "capitalize",
                    fontFamily: "serif",
                    borderRadius: "8px",
                    ":hover": { backgroundColor: "#22345B" },
                  }}
                  disabled={previouscallingBackend}
                >
                  {previouscallingBackend ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Previous"
                  )}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#22345B",
                    textTransform: "capitalize",
                    fontFamily: "serif",
                    borderRadius: "8px",
                    ":hover": { backgroundColor: "#22345B" },
                  }}
                  onClick={submit}
                  disabled={callingBackend}
                >
                  {callingBackend ? <CircularProgress size={24} /> : "Next"}
                </Button>
              </Grid>
              {alert.showAlert && (
                <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                  <Alert severity={alert.severity}>{alert.message}</Alert>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
