import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// import { validationSchema } from "../../utils/validations/limit_liabilityValidation";

const Section1DropDown = [
  "AECoR",
  "Consultant Fees",
  "ECoR",
  "Expediting Costs",
  "Fines & Damages",
  "Personal Property",
  "Professional Fees",
  "Landscaping",
  "Liability to Make Enq.",
  "Removal of Debris",
  "Rewriting of Records",
  "Other",
];

const Section2DropDown = [
  "AICoW",
  "Claims Preparation",
  "Consultant Fees",
  "Contractual Fines",
  "Fines & Damages",
  "Professional Fees",
  "Other",
];

export default function Liability({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const section1 = [
    {
      id: 0,
      name: "ROD",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 1,
      name: "ECOR",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 2,
      name: "AECOR",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 3,
      name: "Expediting Expenses",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 4,
      name: "Please Select",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 5,
      name: "Please Select",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 6,
      name: "Please Select",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 7,
      name: "Please Select",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
  ];
  const section2 = [
    {
      id: 0,
      name: "Payroll",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 1,
      name: "ICOW",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 2,
      name: "AICOW",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 3,
      name: "Claims Preparation",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 4,
      name: "Fines & Damages",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 5,
      name: "Please Select",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 6,
      name: "Please Select",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 7,
      name: "Please Select",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
    {
      id: 8,
      name: "Please Select",
      fire_policy_limit: 0,
      fire_applied: 0,
      mfl_applied: 0,
      nle_applied: 0,
      wle_applied: 0,
      comment: "",
    },
  ];

    const toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      ["link"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["clean"], // remove formatting button
    ];

    const module = {
      toolbar: toolbarOptions,
    };

  
  const [arr, setArr] = useState(formData?.liability?.section1?.length ? [...formData?.liability?.section1] : section1);
  const [arrsec2, setArrsec2] = useState(formData?.liability?.section2?.length ? [...formData?.liability?.section2] :section2);
  const [description, setDescription] = useState(
    formData?.liability?.description ? formData?.liability?.description : ""
  );
  const changeFormData = (value, name, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };

  const changeFormDataSec2 = (value, name, id) => {
    const objIndex = arrsec2?.findIndex((obj) => obj?.id === id);
    arrsec2[objIndex][name] = value;

    setArrsec2([...arrsec2]);
  };

  const submit = async (e, { resetForm }) => {
    const totalOfFireareas1 = arr.reduce(
      (n, { fire_applied, fire_policy_limit }) =>
        n + Number(fire_policy_limit) * (Number(fire_applied) / 100),
      0
    );
    const totalOfFireareas2 = arrsec2.reduce(
      (n, { fire_applied, fire_policy_limit }) =>
        n + Number(fire_policy_limit) * (Number(fire_applied) / 100),
      0
    );
    const totalOfmfls1 = arr.reduce(
      (n, { mfl_applied, fire_applied, fire_policy_limit }) =>
        n +
        Number(fire_policy_limit) *
          (Number(fire_applied) / 100) *
          (Number(mfl_applied) / 100),
      0
    );
    const totalOfmfls2 = arrsec2.reduce(
      (n, { mfl_applied, fire_applied, fire_policy_limit }) =>
        n +
        Number(fire_policy_limit) *
          (Number(fire_applied) / 100) *
          (Number(mfl_applied) / 100),
      0
    );
    const totalOfnles1 = arr.reduce(
      (n, { nle_applied, fire_applied, fire_policy_limit }) =>
        n +
        Number(fire_policy_limit) *
          (Number(fire_applied) / 100) *
          (Number(nle_applied) / 100),
      0
    );
    const totalOfnles2 = arrsec2.reduce(
      (n, { nle_applied, fire_applied, fire_policy_limit }) =>
        n +
        Number(fire_policy_limit) *
          (Number(fire_applied) / 100) *
          (Number(nle_applied) / 100),
      0
    );

      try {
        setCallingBackend(true);

        await axios.put(`/assesment/limit_liability/${state.id}`, {
          section1: arr,
          section2: arrsec2,
          description:description,
          total_of_firearea: Math.round(totalOfFireareas1 + totalOfFireareas2),
          total_of_mfl: Math.round(totalOfmfls1 + totalOfmfls2),
          total_of_nle: Math.round(totalOfnles1 + totalOfnles2),
        });
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Liability page created successfully!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Liability page created successfully!",
            }),
          handleNextPage(),
          5000
        );
      } catch (error) {
        if (error.response.data === "Liability page already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Liability page already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Liability page already exists!",
              }),
            5000
          );
        } else if (error.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Unauthorized Access!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Unauthorized Access!",
              }),
            5000
          );
        } else if (error.response.status === 500) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Server Error!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Server Error!",
              }),
            5000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Liability page already exists!",
          });
          setTimeout(
            () =>
              setAlert({
                showAlert: false,
                severity: "success",
                message: "Liability page already exists!",
              }),
            5000
          );
        }
      }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        onSubmit={submit}
        initialValues={section1}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Sub Limits Of Liability</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={2}>
                      <p className="inputTextH">Section 1</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">Policy Limit</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">Fire Area Applied(%)</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">MFL Applied(%)</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">NLE Applied(%)</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={4}>
                      <p className="inputTextH">Comments</p>{" "}
                    </Grid>
                  </Grid>
                  {arr.map((item, i) => {
                    return (
                      <>
                        {item.id <= 3 ? (
                          <Grid className="inputTextGrid" item xs={2}>
                            <InputFeild
                              name="name"
                              value={item?.name}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "name",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        ) : (
                          <Grid container item xs={2} spacing={1}>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="name"
                                name="name"
                                size="small"
                                value={item.name}
                                options={Section1DropDown}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  changeFormData(newValue, "name", item.id);
                                }}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                        )}
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="fire_policy_limit"
                              value={item?.fire_policy_limit}
                              type="number"
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "fire_policy_limit",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="fire_applied"
                              type="number"
                              value={item?.fire_applied}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "fire_applied",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="mfl_applied"
                              type="number"
                              value={item?.mfl_applied}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "mfl_applied",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="nle_applied"
                              value={item?.nle_applied}
                              type="number"
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "nle_applied",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="comment"
                              value={item?.comment}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "comment",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <Formik
        onSubmit={submit}
        initialValues={section2}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={2}>
                      <p className="inputTextH">Section 2</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={10}></Grid>
                  </Grid>
                  {arrsec2.map((item, i) => {
                    return (
                      <>
                        {item.id <= 4 ? (
                          <Grid className="inputTextGrid" item xs={2}>
                            <InputFeild
                              name="name"
                              value={item?.name}
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "name",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        ) : (
                          <Grid container item xs={2} spacing={1}>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="name"
                                name="name"
                                size="small"
                                value={item.name}
                                options={Section2DropDown}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  changeFormDataSec2(newValue, "name", item.id);
                                }}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                        )}
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="fire_policy_limit"
                              value={item?.fire_policy_limit}
                              type="number"
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "fire_policy_limit",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="fire_applied"
                              type="number"
                              value={item?.fire_applied}
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "fire_applied",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="mfl_applied"
                              type="number"
                              value={item?.mfl_applied}
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "mfl_applied",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="nle_applied"
                              value={item?.nle_applied}
                              type="number"
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "nle_applied",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="comment"
                              value={item?.comment}
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "comment",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: "50px", marginTop: "20px" }}
                  >
                    <p className="inputTextH">
                      Sub Limits Of Liability Comments
                    </p>
                    <ReactQuill
                      modules={module}
                      theme="snow"
                      value={description}
                      placeholder="For Internal Use Only”."
                      style={{
                        marginBottom: "25px",
                        marginTop: "20px",
                        height: "300px",
                      }}
                      onChange={setDescription}
                    />
                  </Grid>
                  <Grid item xs={6} spacing={1}>
                    <pre>{JSON.stringify(formData?.liability, null, 2)}</pre>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={!isValid || callingBackend}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
