import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function WLELiability({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();

  const editItemsSection1 = formData?.wleliability?.wle_edit
    ? [...formData?.wleliability?.section1]
    : [...formData?.liability?.section1];
  const editItemsSection2 = formData?.wleliability?.wle_edit
    ? [...formData?.wleliability?.section2]
    : [...formData?.liability?.section2];

  const [arr, setArr] = useState([...editItemsSection1]);
  const [arrsec2, setArrsec2] = useState([...editItemsSection2]);
  const [calValue, setCalValue] = useState([]);
  const [edit, setEdit] = useState(formData?.wleliability?.wle_edit);

  const changeFormData = (value, name, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };

  const changeFormDataSec2 = (value, name, id) => {
    const objIndex = arrsec2?.findIndex((obj) => obj?.id === id);
    arrsec2[objIndex][name] = value;

    setArrsec2([...arrsec2]);
  };
  const handleWLEChange = (event) => {
    setEdit(event.target.checked);
  };
  const submit = async () => {
    const totalOfwles1 = arr.reduce(
      (n, { wle_applied, fire_applied, fire_policy_limit }) =>
        n +
        Number(fire_policy_limit) *
          (Number(fire_applied) / 100) *
          (Number(wle_applied) / 100),
      0
    );
    const totalOfwles2 = arrsec2.reduce(
      (n, { wle_applied, fire_applied, fire_policy_limit }) =>
        n +
        Number(fire_policy_limit) *
          (Number(fire_applied) / 100) *
          (Number(wle_applied) / 100),
      0
    );
    console.log("totalOfwles1 + totalOfwles2", totalOfwles1);
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/limit_liability/${state.id}`, {
        section1: arr,
        section2: arrsec2,
        total_of_wle: Math.floor(totalOfwles1 + totalOfwles2),
        total_of_firearea: Math.floor(calValue.total_of_firearea),
        total_of_mfl: Math.floor(calValue.total_of_mfl),
        total_of_nle: Math.floor(calValue.total_of_nle),
        wle_edit: edit,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Liability page created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Liability page created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Liability page already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Liability page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Liability page already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Liability page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Liability page already exists!",
            }),
          5000
        );
      }
    }
  };
  const editSubmit = async () => {
    const totalOfFireareas1 = arr.reduce(
      (n, { fire_applied, fire_policy_limit }) =>
        n + Number(fire_policy_limit) * (Number(fire_applied) / 100),
      0
    );
    console.log("totalOfFireareas1", totalOfFireareas1);
    const totalOfFireareas2 = arrsec2.reduce(
      (n, { fire_applied, fire_policy_limit }) =>
        n + Number(fire_policy_limit) * (Number(fire_applied) / 100),
      0
    );
    console.log("totalOfFireareas2", totalOfFireareas2);

    const totalOfwles3 = arr.reduce(
      (n, { wle_applied, fire_applied, fire_policy_limit }) =>
        n +
        Number(fire_policy_limit) *
          (Number(fire_applied) / 100) *
          (Number(wle_applied) / 100),
      0
    );
    console.log("totalOfwles3", totalOfwles3);

    const totalOfwles4 = arrsec2.reduce(
      (n, { wle_applied, fire_applied, fire_policy_limit }) =>
        n +
        Number(fire_policy_limit) *
          (Number(fire_applied) / 100) *
          (Number(wle_applied) / 100),
      0
    );
    console.log("totalOfwles4", totalOfwles4);

    try {
      setCallingBackend(true);
      await axios.put(`/assesment/wle_limit_liability/${state.id}`, {
        section1: arr,
        section2: arrsec2,
        wle_edit: edit,
        total_of_wle: Math.floor(totalOfwles3 + totalOfwles4),
        total_of_firearea: Math.floor(totalOfFireareas1 + totalOfFireareas2),
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Liability page created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Liability page created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Liability page already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Liability page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Liability page already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Liability page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Liability page already exists!",
            }),
          5000
        );
      }
    }
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  const get = async () => {
    try {
      const { data } = await axios.get(`/assesment/${state.id}`);
      if (!edit) {
        setArr([...data?.liability?.section1]);
        setArrsec2([...data?.liability?.section2]);
        setCalValue(data?.liability);
      } else {
        if (data?.wleliability?.section1?.length) {
          setArr([...data?.wleliability?.section1]);
          setArrsec2([...data?.wleliability?.section2]);
          setCalValue(data?.liability);
        } else {
          setArr([...data?.liability?.section1]);
          setArrsec2([...data?.liability?.section2]);
          setCalValue(data?.liability);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, [edit]);
  return (
    <div>
      <Formik
        initialValues={arr}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">WLE Sub Limits Of Liability</p>
                {JSON.stringify(formData?.wleliability?.wle_edit)}
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          checked={edit}
                          onChange={(e) => {
                            handleWLEChange(e);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Edit WLE Sub-limits"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={2}>
                      <p className="inputTextH">Section 1</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">Policy Limit</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">Fire Area Applied(%)</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={1.5}>
                      <p className="inputTextH">WLE Applied(%)</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={5.5}></Grid>
                  </Grid>
                  {arr.map((item, i) => {
                    return (
                      <>
                        <Grid className="inputTextGrid" item xs={2}>
                          <InputFeild
                            name="name"
                            value={item?.name}
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>

                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            {edit ? (
                              <InputFeild
                                name="fire_policy_limit"
                                value={item?.fire_policy_limit}
                                type="number"
                                onChange={(e) =>
                                  changeFormData(
                                    e?.target?.value,
                                    "fire_policy_limit",
                                    item?.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />
                            ) : (
                              <InputFeild
                                name="fire_policy_limit"
                                value={item?.fire_policy_limit}
                                type="number"
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            {edit ? (
                              <InputFeild
                                name="fire_applied"
                                type="number"
                                value={item?.fire_applied}
                                onChange={(e) =>
                                  changeFormData(
                                    e?.target?.value,
                                    "fire_applied",
                                    item?.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />
                            ) : (
                              <InputFeild
                                name="fire_applied"
                                type="number"
                                value={item?.fire_applied}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="wle_applied"
                              type="number"
                              value={item?.wle_applied}
                              onChange={(e) => {
                                changeFormData(
                                  e?.target?.value,
                                  "wle_applied",
                                  item?.id
                                );
                              }}
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={5.5} spacing={1}></Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <Formik
        initialValues={arrsec2}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={2}>
                      <p className="inputTextH">Section 2</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={10}></Grid>
                  </Grid>
                  {arrsec2.map((item, i) => {
                    return (
                      <>
                        <Grid className="inputTextGrid" item xs={2}>
                          <InputFeild
                            name="name"
                            value={item?.name}
                            component={TextField}
                            variant
                            fullWidth
                            bgColor="#FFFFFF"
                            margin="dense"
                          />{" "}
                        </Grid>

                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            {edit ? (
                              <InputFeild
                                name="fire_policy_limit"
                                value={item?.fire_policy_limit}
                                type="number"
                                onChange={(e) =>
                                  changeFormDataSec2(
                                    e?.target?.value,
                                    "fire_policy_limit",
                                    item?.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />
                            ) : (
                              <InputFeild
                                name="fire_policy_limit"
                                value={item?.fire_policy_limit}
                                type="number"
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            {edit ? (
                              <InputFeild
                                name="fire_applied"
                                type="number"
                                value={item?.fire_applied}
                                onChange={(e) =>
                                  changeFormDataSec2(
                                    e?.target?.value,
                                    "fire_applied",
                                    item?.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />
                            ) : (
                              <InputFeild
                                name="fire_applied"
                                type="number"
                                value={item?.fire_applied}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid container item xs={1.5} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="wle_applied"
                              type="number"
                              value={item?.wle_applied}
                              onChange={(e) =>
                                changeFormDataSec2(
                                  e?.target?.value,
                                  "wle_applied",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={5.5} spacing={1}></Grid>
                      </>
                    );
                  })}
                  {edit ? (
                    <Grid item xs={6} spacing={1}>
                      <pre>
                        {JSON.stringify(formData?.wleliability, null, 2)}
                      </pre>
                    </Grid>
                  ) : (
                    <Grid item xs={6} spacing={1}>
                      <pre>{JSON.stringify(formData?.liability, null, 2)}</pre>
                    </Grid>
                  )}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    {edit ? (
                      <Button
                        variant="contained"
                        onClick={() => editSubmit()}
                        sx={{
                          backgroundColor: "#22345B",
                          textTransform: "capitalize",
                          fontFamily: "serif",
                          borderRadius: "8px",
                          ":hover": { backgroundColor: "#22345B" },
                        }}
                        disabled={callingBackend}
                      >
                        {callingBackend ? (
                          <CircularProgress
                            size={25}
                            sx={{ color: "#22345B", margin: "0 5px" }}
                          />
                        ) : (
                          "Next"
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => submit()}
                        sx={{
                          backgroundColor: "#22345B",
                          textTransform: "capitalize",
                          fontFamily: "serif",
                          borderRadius: "8px",
                          ":hover": { backgroundColor: "#22345B" },
                        }}
                        disabled={callingBackend}
                      >
                        {callingBackend ? (
                          <CircularProgress
                            size={25}
                            sx={{ color: "#22345B", margin: "0 5px" }}
                          />
                        ) : (
                          "Next"
                        )}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
