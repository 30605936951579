import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Cities = [
  "Adealide, SA",
  "Albury/ Wodonga, NSW/VIC",
  "Alice Springs, NT",
  "Armidale, NSW",
  "Auckland, NZ",
  "Brisbane, QLD",
  "Broome, WA",
  "Bunbury, WA",
  "Bundaberg, QLD",
  "Cairns, QLD",
  "Canberra, ACT",
  "Christchurch, NZ",
  "Darwin, NT",
  "Dubbo, NSW",
  "Dunedin, NZ",
  "Geelong, VIC",
  "Geraldton, WA",
  "Gladstone, WA",
  "Hamilton, NZ",
  "Hastings, NZ",
  "Hobart, TAS",
  "Kalgoorlie, WA",
  "Launceston, TAS",
  "Mackay, QLD",
  "Melbourne, VIC",
  "Mildura, VIC",
  "Mount Isa, QLD",
  "Napier, NZ",
  "Newcastle, NSW",
  "Perth, WA",
  "Port Hedland, WA",
  "Rockhampton, QLD",
  "Shepparton, VIC",
  "Sydney, NSW",
  "Townsville",
  "Tamworth, NSW",
  "Townsville, QLD",
  "Wagga Wagga, NSW",
  "Wellington, NZ",
  "Whyalla, SA",
  "Wollongong, NSW",
];
export default function NaturalHazardsExposure({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const typeOptions = [
    { name: "Exposure", value: 0.7 },
    { name: "No Exposure", value: 1 },
  ];
  const typeOptionsTwo = [
    { name: "Exposure", value: 0.85 },
    { name: "No Exposure", value: 1 },
  ];

  const natural_hazard = [
    {
      id: 0,
      name: "Flood",
      label: "flood",
      type: { name: "", value: 1 },
      ri: 2,
      description: "",
    },
    {
      id: 1,
      name: "Surface Water",
      label: "surfaceWater",
      type: { name: "", value: 1 },
      ri: 3,
      description: "",
    },
    {
      id: 2,
      name: "Windstorm",
      label: "windstorm",
      type: { name: "", value: 1 },
      ri: 5,
      description: "",
    },
    {
      id: 3,
      name: "Cyclone",
      label: "cyclone",
      type: { name: "", value: 1 },
      ri: 6,
      description: "",
    },
    {
      id: 4,
      name: "Hail",
      label: "hail",
      type: { name: "", value: 1 },
      ri: 7,
      description: "",
    },
    {
      id: 5,
      name: "Bushfire",
      label: "bushfire",
      type: { name: "", value: 1 },
      ri: 8,
      description: "",
    },
    {
      id: 6,
      name: "Earthquake",
      label: "earthquake",
      type: { name: "", value: 1 },
      ri: 9,
      description: "",
    },
    {
      id: 7,
      name: "Other",
      label: "other",
      type: { name: "", value: 1 },
      ri: 1,
      description: "",
    },
  ];

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  const [ccity, setCity] = useState(
    formData?.natural_hazards_exposure?.city
      ? formData?.natural_hazards_exposure?.city
      : ""
  );
  const [selectHazard, setSelectHazard] = useState([]);
  const [arr, setArr] = useState(
    formData?.natural_hazards_exposure?.n_h_exposure?.length
      ? [...formData?.natural_hazards_exposure?.n_h_exposure]
      : natural_hazard
  );

  const changeAutocompleteFormData = (value, name, id, selector) => {
    const datanew = selectHazard?.find((i) => i.location === ccity);
    const objIndex = arr?.findIndex((obj) => obj?.id === id);

    arr[objIndex][name] = value || { name: "", value: 1 };

    if (value?.name === "Exposure") {
      arr[objIndex]["description"] = datanew[selector];
    }
    setArr([...arr]);
  };

  const changeFormData = (value, name, id, selector) => {
    const datanew = selectHazard?.find((i) => i.location === ccity);
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;
    if (value.name === "Exposure") {
      arr[objIndex]["description"] = datanew[selector];
    }
    setArr([...arr]);
  };

  const changeInputValues = (city) => {
    setCity(city);
    const inputsdata = selectHazard?.find((i) => i.location === city);

    if (inputsdata?.windstorm === undefined) {
      arr[2]["description"] = "N/A";
      arr[2]["type"] = { name: "No Exposure", value: 1 };
    } else {
      arr[2]["description"] = inputsdata?.windstorm;
      arr[2]["type"] = { name: "Exposure", value: 0.7 };
    }

    if (inputsdata?.cyclone === undefined) {
      arr[3]["description"] = "N/A";
      arr[3]["type"] = { name: "No Exposure", value: 1 };
    } else {
      arr[3]["description"] = inputsdata?.cyclone;
      arr[3]["type"] = { name: "Exposure", value: 0.85 };
    }

    if (inputsdata?.hail === undefined) {
      arr[4]["description"] = "N/A";
      arr[4]["type"] = { name: "No Exposure", value: 1 };
    } else {
      arr[4]["description"] = inputsdata?.hail;
      arr[4]["type"] = { name: "Exposure", value: 0.7 };
    }

    if (inputsdata?.earthquake === undefined) {
      arr[6]["description"] = "N/A";
      arr[6]["type"] = { name: "No Exposure", value: 1 };
    } else {
      arr[6]["description"] = inputsdata?.earthquake;
      arr[6]["type"] = { name: "Exposure", value: 0.85 };
    }

    setArr([...arr]);
  };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();

  const submit = async (e) => {
    const availableScore = arr.reduce(
      (n, { type }) => n * Number(type?.value),
      1
    );

    try {
      setCallingBackend(true);
      await axios.put(`/assesment/natural_hazards_exposure/${state.id}`, {
        n_h_exposure: arr,
        city: ccity,
        available_score: Math.round(availableScore * 20),
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Natural Hazards Exposure one created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Natural Hazards Exposure one created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (
        error.response.data === "Natural Hazards Exposure one already exists"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Natural Hazards Exposure one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Natural Hazards Exposure one already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Natural Hazards Exposure one already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Natural Hazards Exposure one already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  const getHazard = async () => {
    try {
      const { data } = await axios.get(`/hazardexposure/`);
      setSelectHazard(data);
      console.error("Error fetching RI data:", data);
    } catch (error) {
      console.error("Error fetching RI data:", error);
    }
  };
  useEffect(() => {
    getHazard();
  }, []);
  return (
    <div>
      <Formik
        initialValues={natural_hazard}
        onSubmit={submit}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">PRS AREA - Natural Hazards Exposures</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={12} spacing={1}>
                    {" "}
                    <p className="font">City</p>
                  </Grid>
                  <Grid item xs={12} spacing={1}>
                    <Autocomplete
                      hiddenLabel
                      id="city"
                      name="city"
                      size="small"
                      sx={{ marginBottom: "5px" }}
                      value={ccity}
                      options={Cities}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        changeInputValues(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                  {arr.map((item, i) => {
                    return (
                      <>
                        <Grid item xs={12} sx={{ marginTop: "1.5rem" }}>
                          <p className="font">{item?.name}</p>
                          {item.id === 0 ||
                          item.id === 3 ||
                          item.id === 5 ||
                          item.id === 6 ? (
                            <Autocomplete
                              hiddenLabel
                              id="type"
                              name="type"
                              size="small"
                              sx={{ marginBottom: "5px" }}
                              value={item?.type}
                              options={typeOptions}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                console.log("newValue", newValue);
                                changeAutocompleteFormData(
                                  newValue,
                                  "type",
                                  item.id,
                                  item.label
                                );
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          ) : (
                            <Autocomplete
                              hiddenLabel
                              id="type"
                              name="type"
                              size="small"
                              sx={{ marginBottom: "5px" }}
                              value={item?.type}
                              options={typeOptionsTwo}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                console.log("newValue", newValue);

                                changeAutocompleteFormData(
                                  newValue,
                                  "type",
                                  item.id,
                                  item.label
                                );
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          )}
                          <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                            <ReactQuill
                              modules={module}
                              theme="snow"
                              value={item?.description}
                              style={{
                                marginBottom: "25px",
                                height: "100px",
                              }}
                              onChange={(e) => {
                                changeFormData(e, "description", item.id);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item xs={6} spacing={1}>
                    <pre>
                      {JSON.stringify(
                        formData?.natural_hazards_exposure,
                        null,
                        2
                      )}
                    </pre>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      type="submit"
                      disabled={
                        callingBackend ||
                        arr.some((item) => {
                          if (
                            item.id === 2 ||
                            item.id === 3 ||
                            item.id === 4 ||
                            item.id === 6
                          ) {
                            return (
                              item?.type?.name === "" ||
                              item?.type?.name === null ||
                              item?.type?.name === undefined
                            );
                          }
                          return (
                            item?.type?.name === "" ||
                            item?.type?.name === null ||
                            item?.type?.name === undefined ||
                            item?.description === "" ||
                            item?.description === null ||
                            item?.description === undefined
                          );
                        })
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
