import * as Yup from "yup";

const phoneRegExp = /^(\+?\d{1,4}[\s-]?)?(\(?\d{1,4}\)?[\s-]?)?[\d\s-]{5,}$/;

export const validationSchema = Yup.object().shape({
  company_name: Yup.string()
    .required("Company name is required!")
    .min(3, "Minimum Word count is 3!")
    .max(50, "Maximum Word count is 50!"),
  company_contact_first_name: Yup.string()
    .required("First name is required!")
    .min(3, "Minimum Word count is 3!")
    .max(50, "Maximum Word count is 50!"),
  company_contact_last_name: Yup.string()
    .required("Last name is required!")
    .min(3, "Minimum Word count is 3!")
    .max(50, "Maximum Word count is 50!"),
  company_contact_email: Yup.string()
    .required("Email is required!")
    .trim()
    .matches(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    )
    .max(50, "Maximum character count is 50!"),
  company_contact_number: Yup.string()
    .matches(phoneRegExp, "Company contact number is not valid")
    .required("Company contact number is required!"),
});
