import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "../../libs/axios";

const PDFViewer = () => {
  const { state } = useLocation();
  const [assessmentPdf, setAssessmentPdf] = useState(null);

  const getPage = async () => {
    try {
      const { data } = await axios.get(`/assesment/${state.id}`);
      setAssessmentPdf(data?.pdflink);
    } catch (error) {}
  };
  useEffect(() => {
    getPage();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="formName">Completed Assessment</p>
        <Grid
          container
          xs={10}
          spacing={1}
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          <iframe
            src={assessmentPdf}
            width="100%"
            height="700px"
            title="Assessment Report"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PDFViewer;
