import React, { useState } from "react";
import { Reorder } from "framer-motion";
import { Grid, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import axios from "../../libs/axios";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export default function Rearrange({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const allPriorities = [
    ...(!formData?.construction_priority?.c_check
      ? formData?.construction_priority?.priorityri_c
      : []),
    ...(!formData?.special_hazard_priority?.sh_check
      ? formData?.special_hazard_priority?.priorityri_sh
      : []),
    ...(!formData.fire_detection_protection_priority.fdp_check
      ? formData.fire_detection_protection_priority.priorityri_fdp
      : []),
    ...(!formData?.management_practice_priority?.mp_check
      ? formData?.management_practice_priority?.priorityri_mp
      : []),
    // ...(!formData.natural_hazards_exposure_priority.nhe_check
    //   ? formData.natural_hazards_exposure_priority.priorityri_nhe
    //   : []),
    ...(!formData.natural_hazards_recommendation_priority.nhr_check
      ? formData.natural_hazards_recommendation_priority.priorityri_nhr
      : []),
    ...formData?.new_ri?.ri,
  ];

        const uniquePriorities = allPriorities.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.title === item.title)
    );
    
  const [items, setItems] = useState(
    uniquePriorities?.filter((item) => item?.priority !== "Closed")
  );
  const colsedItems = formData?.priority?.priorityri?.filter(
    (item) => item?.priority === "Closed"
  );
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();
  const submit = async () => {
        const year = new Date().getFullYear();
        const changeItems = items.map((item, index) => {
          if (index + 1 < 10) {
            return {
              ...item,
              code: `${year} - 0${index + 1}`,
            };
          } else {
            return {
              ...item,
              code: `${year} - ${index + 1}`,
            };
          }
        });
        const changeClosedItems = colsedItems.map((item, index) => {
          if (index + items?.length + 1 < 10) {
            return {
              ...item,
              code: `${year} - 0${index + items?.length + 1}`,
            };
          } else {
            return {
              ...item,
              code: `${year} - ${index + items?.length + 1}`,
            };
          }
        });
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/priority/${state.id}`, {
        priorityri: [...changeItems, ...changeClosedItems],
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Priority page created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Priority page created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Priority page already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Priority page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Priority page already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Priority page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Priority page already exists!",
            }),
          5000
        );
      }
    } 
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

    // useEffect(() => {
    //   if (!formData?.priority?.priorityri?.length) {
    //     const allPriorities = [
    //       ...(!formData?.special_hazard_priority?.sh_check
    //         ? formData?.special_hazard_priority?.priorityri_sh
    //         : []),
    //       ...(!formData.fire_detection_protection_priority.fdp_check
    //         ? formData.fire_detection_protection_priority.priorityri_fdp
    //         : []),
    //       ...(!formData?.management_practice_priority?.mp_check
    //         ? formData?.management_practice_priority?.priorityri_mp
    //         : []),
    //       ...(!formData.natural_hazards_exposure_priority.nhe_check
    //         ? formData.natural_hazards_exposure_priority.priorityri_nhe
    //         : []),
    //       ...(!formData.natural_hazards_recommendation_priority.nhr_check
    //         ? formData.natural_hazards_recommendation_priority.priorityri_nhr
    //         : []),
    //       ...formData?.new_ri?.ri,
    //     ];

    //     const uniquePriorities = allPriorities.filter(
    //       (item, index, self) =>
    //         index === self.findIndex((t) => t.title === item.title)
    //     );

    //     setRiPriority(uniquePriorities);
    //   } else {
    //     setRiPriority(formData?.priority?.priorityri);
    //   }
    // }, [formData, selectRi]);
  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="formName">Risk Improvement Order</p>
        <Grid container xs={10} spacing={1}>
          <Grid container item xs={12} spacing={1}>
            <Grid className="inputTextGrid" item xs={8}>
              <p
                className="inputTextH"
                style={{ listStyleType: "none", margin: 0 }}
              >
                Title
              </p>
            </Grid>
            <Grid className="inputTextGrid" item xs={4}>
              <p
                className="inputTextH"
                style={{ listStyleType: "none", margin: 0 }}
              >
                Priority
              </p>
            </Grid>
          </Grid>
          <Reorder.Group
            axis="y"
            values={items}
            onReorder={setItems}
            style={{ width: "100%", listStyleType: "none" }}
          >
            {items.map((item, index) => (
              <Reorder.Item
                key={item.title}
                value={item}
                style={{
                  padding: "0.5rem",
                  margin: "0.5rem 0",
                  border: "1px solid #eee",
                  borderRadius: "5px",
                  cursor: "pointer",
                  listStyleType: "none",
                }}
              >
                <Grid
                  className="inputTextGrid"
                  container
                  alignItems="center"
                  item
                  xs={12}
                >
                  <Grid item xs={1}>
                    {index > 0 && (
                      <ArrowUpwardIcon sx={{ cursor: "pointer" }} />
                    )}
                    {index < items.length - 1 && (
                      <ArrowDownwardIcon sx={{ cursor: "pointer" }} />
                    )}
                  </Grid>
                  <Grid item xs={7} spacing={1}>
                    <p
                      className="inputTextP"
                      style={{ listStyleType: "none", margin: 0 }}
                    >
                      {item?.title}
                    </p>
                  </Grid>
                  <Grid item xs={4} spacing={1} style={{ paddingLeft: "1rem" }}>
                    <p
                      className="inputTextP"
                      style={{ listStyleType: "none", margin: 0 }}
                    >
                      {item?.priority}
                    </p>
                  </Grid>
                </Grid>
              </Reorder.Item>
            ))}
          </Reorder.Group>
          <Grid
            container
            item
            xs={12}
            sx={{
              alignContent: "center",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              onClick={() => handleClick()}
              disabled={previouscallingBackend}
            >
              {previouscallingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Previous"
              )}
            </Button>
            <Button
              variant="contained"
              onClick={() => submit()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={callingBackend}
            >
              {callingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Next"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
