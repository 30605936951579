import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Login from './screens/Assesment/Login';
import NotFound from './screens/Assesment/NotFound';
import { Provider } from "react-redux";
import store from "./screens/store/store";
import AssessmentsTable from './screens/AssessmentsTable/AssessmentsTable';
import PDFViewer from './screens/Stepper7/PdfComplete';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/assessment",
    element: <App />,
  },
  {
    path: "/assessments-view",
    element: <AssessmentsTable />,
  },
  {
    path: "/complete",
    element: <PDFViewer />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);