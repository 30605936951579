import React, { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
// import axios from "../../libs/axios";


// import { Button } from '@mui/material';
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import { useLocation } from "react-router-dom";

// const steps = [
//   {
//     steps: "Stepper 1",
//     form: [
//       "Aditional Assessment Details",
//       "Basic Details",
//       "Operational Overview",
//       "Construction Page",
//       "Star Rating Page 1",
//     ],
//   },
//   {
//     steps: "Stepper 2",
//     form: [
//       "Occupancy Page",
//       "Fire Area Page",
//       "Star Rating Page 2",
//       "Essential Services",
//       "Protection Page 1",
//     ],
//   },
//   {
//     steps: "Stepper 3",
//     form: [
//       "Water Supplies",
//       "Hydrant System",
//       "Towns Main",
//       "Pump 1",
//       "Pump 2",
//     ],
//   },
//   {
//     steps: "Stepper 4",
//     form: [
//       "Pump 3",
//       "Pump 4",
//       "Water Supply Reliability",
//       "Fire Detection",
//       "Alarms, Fire Brigade and First Response",
//     ],
//   },
//   {
//     steps: "Stepper 5",
//     form: [
//       "Star Rating Page 3",
//       "Management Practices",
//       "Natural Hazards Exposure",
//       "Natural Hazards Recommendation",
//       "External Exposures",
//     ],
//   },
//   {
//     steps: "Stepper 6",
//     form: [
//       "Site Security",
//       "Site Photographs Page",
//       "Sub Limits Of Liability",
//       "MFL Page",
//       "NLE page",
//     ],
//   },
//   {
//     steps: "Stepper 7",
//     form: [
//       "WLE Sub Limits Of Liability",
//       "WLE Page",
//       "Special Hazard RI Page",
//       "RI Priority Page",
//       "RI Client Response",
//     ],
//   },
//   {
//     steps: "Stepper 8",
//     form: [
//       "RI Rearrange Page",
//       "RI Client Comment",
//       "Executive Summary",
//       "Additional Details",
//       "PDF",
//       "Data",
//     ],
//   },
// ];

    // const { state } = useLocation();

const steps = [
  {
    steps: "Stepper 1",
    form: [
      { formNo: 1, name: "Aditional Assessment Details" },
      { formNo: 2, name: "Operational Overview" },
      { formNo: 3, name: "Construction" },
      { formNo: 4, name: "Overall Construction Classification" },
      { formNo: 5, name: "Construction RI" },
    ],
  },
  {
    steps: "Stepper 2",
    form: [
      { formNo: 6, name: "Occupancy" },
      { formNo: 7, name: "Fire Area" },
      { formNo: 8, name: "Special Hazards" },
      { formNo: 9, name: "Special Hazards RI" },
      { formNo: 10, name: "Essential Services" },
    ],
  },
  {
    steps: "Stepper 3",
    form: [
      { formNo: 11, name: "Sprinkler Systems" },
      { formNo: 12, name: "Sprinkler System Demands" },
      { formNo: 13, name: "Hydrant Systems" },
      { formNo: 14, name: "Towns Main Water Supply" },
      { formNo: 15, name: "Pump 1 Water Supply" },
    ],
  },
  {
    steps: "Stepper 4",
    form: [
      { formNo: 16, name: "Pump 2 Water Supply" },
      { formNo: 17, name: "Pump 3 Water Supply" },
      { formNo: 18, name: "Pump 4 Water Supply" },
      { formNo: 19, name: "Fire Detection" },
      { formNo: 20, name: "Other Fire Response" },
    ],
  },
  {
    steps: "Stepper 5",
    form: [
      { formNo: 21, name: "Fire Detection and Protection" },
      { formNo: 22, name: "Fire Detection and Protection RI" },
      { formNo: 23, name: "Management Practices" },
      { formNo: 24, name: "Management Practices RI" },
      { formNo: 25, name: "Natural Hazards Exposure" },
    ],
  },
  {
    steps: "Stepper 6",
    form: [
      { formNo: 26, name: "Natural Hazards Recommendation" },
      { formNo: 27, name: "Natural Hazards RI" },
      { formNo: 28, name: "External Exposures" },
      { formNo: 29, name: "Site Security" },
      { formNo: 30, name: "Site Photographs" },
    ],
  },
  {
    steps: "Stepper 7",
    form: [
      { formNo: 31, name: "Basic Details" },
      { formNo: 32, name: "Sublimits Of Liability" },
      { formNo: 33, name: "Maximum Foreseable Loss (MFL)" },
      { formNo: 34, name: "Normal Loss Expectancy (NLE)" },
      { formNo: 35, name: "WLE Sublimits Of Liability" },
    ],
  },
  {
    steps: "Stepper 8",
    form: [
      { formNo: 36, name: "Windstorm Loss Expectancy (WLE)" },
      { formNo: 37, name: "Special Hazard RI" },
      { formNo: 38, name: "Risk Improvement Priority" },
      { formNo: 39, name: "RI Rearrange" },
      { formNo: 40, name: "Executive Summary" },
    ],
  },
  {
    steps: "Stepper 9",
    form: [
      { formNo: 41, name: "Additional Details" },
      { formNo: 42, name: "PDF" },
      { formNo: 43, name: "Data" },
    ],
  },
];

const wlesteps = [
  {
    steps: "Stepper 1",
    form: [
      { formNo: 1, name: "Aditional Assessment Details" },
      { formNo: 2, name: "Operational Overview" },
      { formNo: 3, name: "Construction" },
      { formNo: 4, name: "Overall Construction Classification" },
      { formNo: 5, name: "Construction RI" },
    ],
  },
  {
    steps: "Stepper 2",
    form: [
      { formNo: 6, name: "Occupancy" },
      { formNo: 7, name: "Fire Area" },
      { formNo: 8, name: "Special Hazards" },
      { formNo: 9, name: "Special Hazards RI" },
      { formNo: 10, name: "Essential Services" },
    ],
  },
  {
    steps: "Stepper 3",
    form: [
      { formNo: 11, name: "Sprinkler Systems" },
      { formNo: 12, name: "Sprinkler System Demands" },
      { formNo: 13, name: "Hydrant Systems" },
      { formNo: 14, name: "Towns Main Water Supply" },
      { formNo: 15, name: "Pump 1 Water Supply" },
    ],
  },
  {
    steps: "Stepper 4",
    form: [
      { formNo: 16, name: "Pump 2 Water Supply" },
      { formNo: 17, name: "Pump 3 Water Supply" },
      { formNo: 18, name: "Pump 4 Water Supply" },
      { formNo: 19, name: "Fire Detection" },
      { formNo: 20, name: "Other Fire Response" },
    ],
  },
  {
    steps: "Stepper 5",
    form: [
      { formNo: 21, name: "Fire Detection and Protection" },
      { formNo: 22, name: "Fire Detection and Protection RI" },
      { formNo: 23, name: "Management Practices" },
      { formNo: 24, name: "Management Practices RI" },
      { formNo: 25, name: "Natural Hazards Exposure" },
    ],
  },
  {
    steps: "Stepper 6",
    form: [
      { formNo: 26, name: "Natural Hazards Recommendation" },
      { formNo: 27, name: "Natural Hazards RI" },
      { formNo: 28, name: "External Exposures" },
      { formNo: 29, name: "Site Security" },
      { formNo: 30, name: "Site Photographs" },
    ],
  },
  {
    steps: "Stepper 7",
    form: [
      { formNo: 31, name: "Basic Details" },
      { formNo: 32, name: "Sublimits Of Liability" },
      { formNo: 33, name: "Maximum Foreseable Loss (MFL)" },
      { formNo: 34, name: "Normal Loss Expectancy (NLE)" },
      { formNo: 35, name: "WLE Sublimits Of Liability" },
    ],
  },
  {
    steps: "Stepper 8",
    form: [
      { formNo: 36, name: "Windstorm Loss Expectancy (WLE)" },
      { formNo: 37, name: "Special Hazard RI" },
      { formNo: 38, name: "Risk Improvement Priority" },
      { formNo: 39, name: "RI Rearrange" },
      { formNo: 40, name: "Executive Summary" },
    ],
  },
  {
    steps: "Stepper 9",
    form: [
      { formNo: 41, name: "Additional Details" },
      { formNo: 42, name: "PDF" },
      { formNo: 43, name: "Data" },
    ],
  },
];
const wlewatersteps = [
  {
    steps: "Stepper 1",
    form: [
      { formNo: 1, name: "Aditional Assessment Details" },
      { formNo: 2, name: "Operational Overview" },
      { formNo: 3, name: "Construction" },
      { formNo: 4, name: "Overall Construction Classification" },
      { formNo: 5, name: "Construction RI" },
    ],
  },
  {
    steps: "Stepper 2",
    form: [
      { formNo: 6, name: "Occupancy" },
      { formNo: 7, name: "Fire Area" },
      { formNo: 8, name: "Special Hazards" },
      { formNo: 9, name: "Special Hazards RI" },
      { formNo: 10, name: "Essential Services" },
    ],
  },
  {
    steps: "Stepper 3",
    form: [
      { formNo: 11, name: "Sprinkler Systems" },
      { formNo: 12, name: "Sprinkler System Demands" },
      { formNo: 13, name: "Hydrant Systems" },
      { formNo: 14, name: "Towns Main Water Supply" },
      { formNo: 15, name: "Pump 1 Water Supply" },
    ],
  },
  {
    steps: "Stepper 4",
    form: [
      { formNo: 16, name: "Pump 2 Water Supply" },
      { formNo: 17, name: "Pump 3 Water Supply" },
      { formNo: 18, name: "Pump 4 Water Supply" },
      { formNo: 19, name: "Fire Detection" },
      { formNo: 20, name: "Other Fire Response" },
    ],
  },
  {
    steps: "Stepper 5",
    form: [
      { formNo: 21, name: "Fire Detection and Protection" },
      { formNo: 22, name: "Fire Detection and Protection RI" },
      { formNo: 23, name: "Management Practices" },
      { formNo: 24, name: "Management Practices RI" },
      { formNo: 25, name: "Natural Hazards Exposure" },
    ],
  },
  {
    steps: "Stepper 6",
    form: [
      { formNo: 26, name: "Natural Hazards Recommendation" },
      { formNo: 27, name: "Natural Hazards RI" },
      { formNo: 28, name: "External Exposures" },
      { formNo: 29, name: "Site Security" },
      { formNo: 30, name: "Site Photographs" },
    ],
  },
  {
    steps: "Stepper 7",
    form: [
      { formNo: 31, name: "Basic Details" },
      { formNo: 32, name: "Sublimits Of Liability" },
      { formNo: 33, name: "Maximum Foreseable Loss (MFL)" },
      { formNo: 34, name: "Normal Loss Expectancy (NLE)" },
      { formNo: 35, name: "WLE Sublimits Of Liability" },
    ],
  },
  {
    steps: "Stepper 8",
    form: [
      { formNo: 36, name: "Windstorm Loss Expectancy (WLE)" },
      { formNo: 37, name: "Special Hazard RI" },
      { formNo: 38, name: "Risk Improvement Priority" },
      { formNo: 39, name: "RI Rearrange" },
      { formNo: 40, name: "Executive Summary" },
    ],
  },
  {
    steps: "Stepper 9",
    form: [
      { formNo: 41, name: "Additional Details" },
      { formNo: 42, name: "PDF" },
      { formNo: 43, name: "Data" },
    ],
  },
];
const watersteps = [
  {
    steps: "Stepper 1",
    form: [
      { formNo: 1, name: "Aditional Assessment Details" },
      { formNo: 2, name: "Operational Overview" },
      { formNo: 3, name: "Construction" },
      { formNo: 4, name: "Overall Construction Classification" },
      { formNo: 5, name: "Construction RI" },
    ],
  },
  {
    steps: "Stepper 2",
    form: [
      { formNo: 6, name: "Occupancy" },
      { formNo: 7, name: "Fire Area" },
      { formNo: 8, name: "Special Hazards" },
      { formNo: 9, name: "Special Hazards RI" },
      { formNo: 10, name: "Essential Services" },
    ],
  },
  {
    steps: "Stepper 3",
    form: [
      { formNo: 11, name: "Sprinkler Systems" },
      { formNo: 12, name: "Sprinkler System Demands" },
      { formNo: 13, name: "Hydrant Systems" },
      { formNo: 14, name: "Towns Main Water Supply" },
      { formNo: 15, name: "Pump 1 Water Supply" },
    ],
  },
  {
    steps: "Stepper 4",
    form: [
      { formNo: 16, name: "Pump 2 Water Supply" },
      { formNo: 17, name: "Pump 3 Water Supply" },
      { formNo: 18, name: "Pump 4 Water Supply" },
      { formNo: 19, name: "Fire Detection" },
      { formNo: 20, name: "Other Fire Response" },
    ],
  },
  {
    steps: "Stepper 5",
    form: [
      { formNo: 21, name: "Fire Detection and Protection" },
      { formNo: 22, name: "Fire Detection and Protection RI" },
      { formNo: 23, name: "Management Practices" },
      { formNo: 24, name: "Management Practices RI" },
      { formNo: 25, name: "Natural Hazards Exposure" },
    ],
  },
  {
    steps: "Stepper 6",
    form: [
      { formNo: 26, name: "Natural Hazards Recommendation" },
      { formNo: 27, name: "Natural Hazards RI" },
      { formNo: 28, name: "External Exposures" },
      { formNo: 29, name: "Site Security" },
      { formNo: 30, name: "Site Photographs" },
    ],
  },
  {
    steps: "Stepper 7",
    form: [
      { formNo: 31, name: "Basic Details" },
      { formNo: 32, name: "Sublimits Of Liability" },
      { formNo: 33, name: "Maximum Foreseable Loss (MFL)" },
      { formNo: 34, name: "Normal Loss Expectancy (NLE)" },
      { formNo: 35, name: "WLE Sublimits Of Liability" },
    ],
  },
  {
    steps: "Stepper 8",
    form: [
      { formNo: 36, name: "Windstorm Loss Expectancy (WLE)" },
      { formNo: 37, name: "Special Hazard RI" },
      { formNo: 38, name: "Risk Improvement Priority" },
      { formNo: 39, name: "RI Rearrange" },
      { formNo: 40, name: "Executive Summary" },
    ],
  },
  {
    steps: "Stepper 9",
    form: [
      { formNo: 41, name: "Additional Details" },
      { formNo: 42, name: "PDF" },
      { formNo: 43, name: "Data" },
    ],
  },
];

export default function FormStepperNav({ page, setPage, getPage, formData }) {
  // eslint-disable-next-line
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeFormStep, setActiveFormStep] = React.useState(1);
  const [waterExclude, setWaterExclude] = useState(false);
    const [waterhydrantExclude, setWaterhydrantExclude] = useState(false);
   const [wleExclude, setWleExclude] = useState(false);
   const [filteredSteps, setFilteredSteps] = useState([]);
  const stepperNo = [5, 10, 15, 20, 25, 30, 35, 40];
  let totalPages = 0;

  useEffect(() => {
    setActiveStep(page - 1);
    setActiveFormStep(totalPages);
    setWleExclude(formData?.assessment?.wle_exclude);
    setWaterExclude(formData?.assessment?.water_supply_exclude);
    setWaterhydrantExclude(
      formData?.assessment?.hysrant_water_supply_exclude
    );

    const fillter =
      wleExclude && waterExclude && waterhydrantExclude
        ? wlewatersteps
        : wleExclude && !waterExclude && !waterhydrantExclude
        ? wlesteps
        : wleExclude && !waterExclude && waterhydrantExclude
        ? wlesteps
        : wleExclude && waterExclude && !waterhydrantExclude
        ? wlesteps
        : !wleExclude && waterExclude && waterhydrantExclude
        ? watersteps
        : !wleExclude && !waterExclude && waterhydrantExclude
        ? watersteps
        : !wleExclude && waterExclude && !waterhydrantExclude
        ? watersteps
        : steps;

    setFilteredSteps(fillter);
console.log("fillter", fillter);
// eslint-disable-next-line
  }, [page, totalPages, formData, steps]);

  let completeCount = 0;

  for (let i = 0; i < stepperNo.length; i++) {
    if (stepperNo[i] >= page) {
      totalPages = i;
      completeCount = stepperNo[totalPages - 1];
      break; // Exit the loop after finding the first match
    }
  }
  // class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root"
  // const changeStep = () => {
  //   setActiveFormStep(activeFormStep + 1);
  // };
  // const changePreviousStep = () => {
  //   setActiveFormStep(activeFormStep - 1);
  // };

  // const handlePageNumber = async (fNo) => {
  //   try {
  //     await axios.put(`/assesment/previous/${formData._id}`, {
  //       form_number: fNo,
  //     });
  //     setTimeout(() => getPage(), 1000);
  //   } catch (error) {}
  // };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        padding: "0 1rem",
      }}
    >
      <Box sx={{ width: "90%" }}>
        {filteredSteps.length > 0 && page <= 5 && (
          <Stepper activeStep={page - 1}>
            {filteredSteps[activeFormStep]?.form.map((label, index) => (
              <Step key={label.formNo}>
                <StepButton
                  color="inherit"
                  // onClick={() => handlePageNumber(label.formNo)}
                >
                  {label.name}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
        {filteredSteps.length > 0 && page > 5 && (
          <Stepper activeStep={page - completeCount - 1}>
            {filteredSteps[activeFormStep]?.form.map((label, index) => (
              <Step key={label.formNo}>
                <StepButton
                  color="inherit"
                  // onClick={() => handlePageNumber(label.formNo)}
                >
                  {label.name}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
    </Box>
  );
}
