import { Grid, Typography, Button } from "@mui/material";
import { logout } from "../../screens/store/actions/authActions";
import React from 'react'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    let navigate = useNavigate();

  let dispatch = useDispatch();
   const onLogout = () => {
     dispatch(logout());
     navigate("/");
   };
  return (
    <div>
      <Grid
        container
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item sx={{ textAlign: "center" }}>
          <Typography sx={{ fontSize: "15rem", fontWeight: "bold" }}>
            404
          </Typography>
          <Typography sx={{ fontSize: "1.2rem" }}>Page Not Found!</Typography>
          <Button
            onClick={() => onLogout()}
            sx={{
              padding: "8px 12px",
              marginTop:"1rem",
              borderRadius: "50px",
              backgroundColor: "#22345B",
              textTransform: "capitalize",
              color:"#fff",
              fontSize: "1rem",
              fontFamily: "serif",
              ":hover": { backgroundColor: "#22345B", color: "#fff" },
            }}
          >
             Back to home
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
