import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "../../libs/axios";



export default function AlertDialog({ id, setSnackalert, loadData,name }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onDelete = async (id) => {
    try {
      await axios.delete(`/assesment/${id}`);
      setSnackalert(true);
      setTimeout(() => setSnackalert(false), 5000);
      loadData();
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Button
        onClick={handleClickOpen}
        sx={{
          marginLeft: "5px",
          backgroundColor: "#b19777",
          textTransform: "capitalize",
          fontFamily: "serif",
          borderRadius: "8px",
          color: "#ffffff",
          ":hover": { backgroundColor: "#b19777" },
        }}
      >
        <DeleteIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 700 }}>
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: "0.9rem" }}
          >
            Are you sure you want to delete the Assessment '{name}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            sx={{
              fontSize: "0.9rem",
              backgroundColor: "#22345B",
              textTransform: "capitalize",
              borderRadius: "8px",
              color: "#ffffff",
              fontFamily: "serif",
              ":hover": { backgroundColor: "#22345B" },
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => onDelete(id)}
            sx={{
              fontSize: "0.9rem",
              textTransform: "capitalize",
              backgroundColor: "red",
              color: "#ffffff",
              borderRadius: "8px",
              fontFamily: "serif",
              ":hover": { backgroundColor: "red", color: "#ffffff" },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
