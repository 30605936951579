import React from "react";
import MainScreen from './screens/Assesment/MainScreen';

function App() {
  return (
    <div>
        <MainScreen />
    </div>
  );
}

export default App;
