import React, { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function BasicDetails({
  getPage,
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
  skipcallingBackend,
  setSkipCallingBackend,
}) {
  const { state } = useLocation();
  const section1 = [
    {
      id: 0,
      name: "Building",
      value: 0,
    },
    {
      id: 1,
      name: "Contents",
      value: 0,
    },
    {
      id: 2,
      name: "Stocks",
      value: 0,
    },
    {
      id: 3,
      name: "Other",
      value: 0,
    },
    {
      id: 4,
      name: "Other",
      value: 0,
    },
    {
      id: 5,
      name: "Other",
      value: 0,
    },
  ];
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [arr, setArr] = useState(section1);
  const [businessInteruption, setBusinessInteruption] = useState(0);
  const [interuptionPeriod, setInteruptionPeriod] = useState('');
const [policyLimit, setpolicyLimit] = useState(0);

  const [initialValues, setInitialValues] = useState({
    business_interuption: "",
    interuption_period: "",
    bi_over_ip: "",
    section1_total: "",
    section2_total: "",
    total_s1_s2: "",
    policy_limit: "",
  });

  const changeFormData = (value, name, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };

  const submit = async (e) => {
    const section1Total = arr.reduce((n, { value }) => n + Number(value), 0);
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/basic_details/${state.id}`, {
        section1: arr,
        business_interuption: Math.floor(businessInteruption),
        interuption_period: interuptionPeriod,
        bi_over_ip: Math.floor(businessInteruption / interuptionPeriod),
        section1_total: Math.floor(section1Total),
        section2_total: Math.floor(businessInteruption),
        total_s1_s2:
          Math.floor(section1Total) + Math.floor(businessInteruption),
        policy_limit: policyLimit,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Basic Details page created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Basic Details page created successfully!",
          }),
        getPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Basic Details page already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Basic Details page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Basic Details page already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Basic Details page already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Basic Details page already exists!",
            }),
          5000
        );
      }
    } finally {
      setCallingBackend(false)
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  const handleNext = () => {
    setSkipCallingBackend(true);
    handleNextPage();
  };
  useEffect(() => {
    setInitialValues({
      business_interuption: formData.basic_details.business_interuption,
      interuption_period: formData.basic_details.interuption_period,
      bi_over_ip: formData.basic_details.bi_over_ip,
      section1_total: formData.basic_details.section1_total,
      section2_total: formData.basic_details.section2_total,
      total_s1_s2: formData.basic_details.total_s1_s2,
      policy_limit: formData.basic_details.policy_limit,
    });
    setBusinessInteruption(formData.basic_details.business_interuption);
    setInteruptionPeriod(formData.basic_details.interuption_period);
    setpolicyLimit(formData.basic_details.policy_limit);
    if (formData?.basic_details?.section1?.length) {
      setArr([...formData?.basic_details?.section1]);
    }
  }, [formData]);
  return (
    <div>
      <Formik
        initialValues={section1}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Declared Values</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={6}>
                      <p className="inputTextH">Section 1</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={6}>
                      <p className="inputTextH">AUD $</p>{" "}
                    </Grid>
                  </Grid>
                  {arr.map((item, i) => {
                    return (
                      <>
                        <Grid className="inputTextGrid" item xs={6}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="name"
                              value={item?.name}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "name",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>

                        <Grid container item xs={6} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="value"
                              value={item?.value}
                              type="number"
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "value",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid className="inputTextGrid" item xs={6}>
                      <p className="inputTextH">Section 2</p>{" "}
                    </Grid>
                    <Grid className="inputTextGrid" item xs={6}>
                      <p className="inputTextH">AUD $</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid className="inputTextGrid" item xs={6}>
                    <Grid item xs={12}>
                      <p>Business Interuption</p>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                      <InputFeild
                        name="business_interuption"
                        value={businessInteruption}
                        type="number"
                        onChange={(e) => {
                          setBusinessInteruption(e.target.value);
                        }}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />{" "}
                    </Grid>
                  </Grid>
                  <Grid className="inputTextGrid" item xs={6}>
                    <Grid item xs={12}>
                      <p>Indemnity Period</p>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        name="interuption_period"
                        value={interuptionPeriod}
                        type="number"
                        onChange={(e) => {
                          setInteruptionPeriod(e.target.value);
                        }}
                        InputProps={{
                          inputProps: { step: "0.01", min: "0" },
                        }}
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="dense"
                        style={{ backgroundColor: "#FFFFFF" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid className="inputTextGrid" item xs={6}>
                    <Grid item xs={12}>
                      <p>Policy Limit Of Liability</p>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                      <InputFeild
                        name="policy_limit"
                        value={policyLimit}
                        type="number"
                        onChange={(e) => {
                          setpolicyLimit(e.target.value);
                        }}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "flex-end",
                      marginTop: "5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={!isValid || callingBackend}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && (
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid item md={8}>
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, showAlert: false })}
            >
              {alert.message}
            </Alert>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        xs={12}
        sx={{
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container xs={10} spacing={1}>
          <Grid className="inputTextGrid" item xs={12}>
            <Grid item xs={6}>
              <p>Section 1 Total</p>
            </Grid>
            <Grid item xs={6}>
              <p>= {initialValues.section1_total || 0}</p>
            </Grid>
          </Grid>
          <Grid className="inputTextGrid" item xs={12}>
            <Grid item xs={6}>
              <p>BI/IP</p>
            </Grid>
            <Grid item xs={6}>
              <p>= {initialValues.bi_over_ip || 0}</p>
            </Grid>
          </Grid>
          <Grid className="inputTextGrid" item xs={12}>
            <Grid item xs={6}>
              <p>Section 2 Total</p>
            </Grid>
            <Grid item xs={6}>
              <p>= {initialValues.section2_total || 0}</p>
            </Grid>
          </Grid>
          <Grid className="inputTextGrid" item xs={12}>
            <Grid item xs={6}>
              <p>Total Declared Values (Section 1 + Section 2)</p>
            </Grid>
            <Grid item xs={6}>
              <p>= {initialValues.total_s1_s2 || 0}</p>
            </Grid>
          </Grid>
          <Grid item xs={6} spacing={1}>
            <pre>{JSON.stringify(formData.basic_details, null, 2)}</pre>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              alignContent: "center",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleClick()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={previouscallingBackend}
            >
              {previouscallingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Previous"
              )}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleNext()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={skipcallingBackend || !initialValues.total_s1_s2}
            >
              {skipcallingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Next"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
